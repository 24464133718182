/**
 * Format the duration/position in the player in mm:ss.
 * N.B. The far-left digit never gets padded (inspired by Netflix, Spotify, etc.).
 * @param {time} the duration/position in seconds.
 */
export function displayTime(time: number) {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - 3600 * hours) / 60)
    const seconds = Math.floor(modulo(time - 60 * minutes, 60))
    const minutesString = hours > 0 ? stringPadLeft(minutes.toString()) : minutes.toString()
    return `${hours > 0 ? `${hours.toString()}:` : ''}${minutesString}:${stringPadLeft(seconds.toString())}`
}

/**
 * Make sure you always get a positive value returned (e.g., 5 instead of -55 for % 60).
 */
export function modulo(n: number, m: number) {
    return ((n % m) + m) % m
}

/**
 * Display for instance 00:34 intead of 0:34.
 */
export function stringPadLeft(text: string) {
    return (new Array(3).join('0') + text).slice(-2)
}

/**
 * Since we use presigned URLs and these URLs can change between queries, check if the base file is the same, and if
 * the loaded source link has not expired.
 * N.B. Presigned AWS links have a 10-digit Unix timestamp as the expiration timestamp (measured in seconds, not milliseconds).
 */
export function isSameSourceLoaded(loadedSource: string, checkSource: string) {
    if (!loadedSource || !checkSource) return false
    const loadedURL = new URL(loadedSource)
    const checkURL = new URL(checkSource)
    const expirationTimestamp = parseInt(loadedURL.searchParams.get('Expires')) // Unix in seconds — not milliseconds.
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const stillValid = expirationTimestamp ? currentTimestamp < expirationTimestamp : true
    return loadedURL.pathname === checkURL.pathname && stillValid
}
