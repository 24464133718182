import React, { lazy, Suspense, useState } from 'react'

import { LOCAL } from 'config'
import importWithRetry from 'utils/import-with-retry'

/**
 * Inject react-query's dev tools in Local without impacting the bundle size in Production, nor obfuscating the Cypress
 * video outputs — unless explicitly toggled on to help with debugging!
 */
export default function ReactQueryDevTools() {
    // @ts-ignore
    const [showDevtools, setShowDevtools] = useState(globals.environment === LOCAL && !window.Cypress)

    React.useEffect(() => {
        // @ts-ignore
        window.toggleDevtools = () => setShowDevtools((old) => !old)
    }, [])

    if (showDevtools) {
        const LazyReactQueryDevtools = lazy(() =>
            // @ts-ignore
            importWithRetry(() => import('@tanstack/react-query-devtools')).then((module) => ({
                default: module.ReactQueryDevtools
            }))
        )
        return (
            <Suspense fallback={null}>
                <LazyReactQueryDevtools initialIsOpen />
            </Suspense>
        )
    } else {
        return null
    }
}
