import React from 'react'

import { LOCAL } from 'config'
import useEffectOnce from 'hooks/useEffectOnce'
import { getVariables } from 'theme'
import { COORDINATOR_EMAIL } from 'utils/misc'
import { forceSentryLoading } from 'utils/monitoring'

interface FallbackComponentProps {
    componentDisplayName?: string
    componentStack?: string
    error: Error
}

/**
 * Basic component to render in case of exception caught.
 * Make the body useful in Local by displaying some information about the error.
 */
export default function FallbackComponent({ componentDisplayName, error }: FallbackComponentProps) {
    useEffectOnce(() => {
        forceSentryLoading()
    })

    const containerStyle = componentDisplayName
        ? {
              // Same styling as our outlined MuiPaper.
              border: `solid 1px ${getVariables().layoutGrey}`,
              borderRadius: '12px',
              margin: '25px',
              padding: '15px 25px'
          }
        : {
              // There is no longer a proper parent Container, so add some padding manually.
              padding: '50px'
          }

    const errorDetails = globals.environment === LOCAL && <p>Debug | {error.toString()}</p>

    if (error.name === 'ChunkLoadError') {
        return (
            <div style={containerStyle}>
                <h2>Hmm. It seems there was a lost connection!</h2>
                <p>Please try reloading the page</p>
                <button onClick={() => window.location.reload()}>Reload</button>
                <p>
                    Otherwise, tell us at{' '}
                    <a style={{ color: 'inherit' }} href={`mailto:${COORDINATOR_EMAIL}`}>
                        {COORDINATOR_EMAIL}
                    </a>
                    &nbsp;or{' '}
                    <a style={{ color: 'inherit', display: 'inline-block' }} href={globals.phoneNumberHref}>
                        {globals.phoneNumber}
                    </a>
                    .
                </p>
                <p>We'll get this fixed in a jiffy!</p>
                {errorDetails}
            </div>
        )
    } else if (error.name === 'RouteNotFoundError') {
        return (
            <div style={containerStyle}>
                <h2>Oops! There’s a lost connection.</h2>
                <p>We can’t find the page you’re looking for.</p>
                {errorDetails}
            </div>
        )
    } else {
        const header = componentDisplayName
            ? `Hmm. We could not load ${componentDisplayName}...`
            : 'Hmm. It seems we have an error—our fault, not yours!'
        return (
            <div style={containerStyle}>
                <h2>{header}</h2>
                <p>
                    Tell us at{' '}
                    <a style={{ color: 'inherit' }} href={`mailto:${COORDINATOR_EMAIL}`}>
                        {COORDINATOR_EMAIL}
                    </a>
                    &nbsp;or{' '}
                    <a style={{ color: 'inherit', display: 'inline-block' }} href={globals.phoneNumberHref}>
                        {globals.phoneNumber}
                    </a>
                    .
                </p>
                <p>We'll get this fixed in a jiffy!</p>
                {errorDetails}
            </div>
        )
    }
}
