import React, { createContext, useEffect, useRef, useState } from 'react'

import type NoSleepType from 'nosleep.js'

import useEffectOnce from 'hooks/useEffectOnce'
import usePageName from 'hooks/usePageName'

interface SitbackContextInterface {
    isSitbackActive: boolean
    setIsSitbackActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const SitbackContext = createContext<SitbackContextInterface>(null)

interface SitbackProviderProps {
    children: React.ReactNode
}

/**
 * Provider for the sitback experience, implementing the nosleep library to make sure the screen stays awake.
 * N.B. The nosleep library will raise an error if we try to enable wake lock while the tab is not active.
 */
export default function SitbackProvider({ children }: SitbackProviderProps) {
    const [isDocumentActive, setIsDocumentActive] = useState(document.visibilityState === 'visible')
    const [isSitbackActive, setIsSitbackActive] = useState(false)
    const pageName = usePageName()
    const noSleep = useRef<NoSleepType>()

    useEffectOnce(() => {
        import('nosleep.js').then((NoSleep) => {
            noSleep.current = new NoSleep.default()
        })
        const handleVisibilityChange = () => setIsDocumentActive(document.visibilityState === 'visible')
        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
    })

    useEffect(() => {
        if (isDocumentActive && isSitbackActive) {
            noSleep.current?.enable()
        }
        return () => noSleep.current?.disable()
    }, [isDocumentActive, isSitbackActive])

    useEffect(() => {
        if (isSitbackActive && !['episode_detail', 'holiday_card_detail', 'playlist_detail'].includes(pageName)) {
            setIsSitbackActive(false)
        }
    }, [isSitbackActive, pageName])

    return <SitbackContext.Provider value={{ isSitbackActive, setIsSitbackActive }}>{children}</SitbackContext.Provider>
}
