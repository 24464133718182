import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useGlobalAudioPlayer as useVendorAudioPlayer } from 'react-use-audio-player'

import { AudioPlayerContext } from 'contexts/AudioPlayerProvider'
import { captureMessage, ERROR_LEVEL } from 'utils/monitoring'
import axios from 'utils/network'

interface AudioPositionContextInterface {
    duration: number
    isUserSeekingRef: React.MutableRefObject<boolean>
    position: number
    seek: (position: number) => void
    seekBarPosition: number
    setSeekBarPosition: React.Dispatch<React.SetStateAction<number>>
}

export const AudioPositionContext = createContext<AudioPositionContextInterface | null>(null)

interface AudioJumpContextInterface {
    jumpBackward: () => void
    jumpForward: () => void
}

export const AudioJumpContext = createContext<AudioJumpContextInterface | null>(null)

interface AudioPositionProviderProps {
    children: React.ReactNode
}

/**
 * This context provider is to be used by any component displaying the seek bar for the audio currently playing.
 * The main reasons for this context:
 *     - Enable sharing the `position` state variable across seek bars.
 *     - Only have one `requestAnimationFrame` loop, to avoid performance issues.
 * This context is distinct from the audio player context, because audio context is used in a lot of components,
 * including `EpisodeDetail`, and any update of `position` would trigger way too many re-renders.
 */
export default function AudioPositionProvider({ children }: AudioPositionProviderProps) {
    const { audioProperties } = useContext(AudioPlayerContext)
    const { duration, getPosition, playing: isPlaying, seek } = useVendorAudioPlayer()

    // Use `requestAnimationFrame` for a smooth but efficient rate update. Indeed, it is preferred to simpler methods
    // like `setInterval` for performance, to prevent bad interactions with the page noticed when the audio is playing.
    // @see https://hacks.mozilla.org/2011/08/animating-with-javascript-from-setinterval-to-requestanimationframe/
    // @see https://github.com/E-Kuerschner/useAudioPlayer#recipe-syncing-react-state-to-live-audio-position
    const [position, setPosition] = useState(0.0) // Aka audio position, as opposed to the progress bar position below.
    const positionFrameRef = useRef<number>()

    useEffect(() => {
        const animate = () => {
            setPosition(getPosition())
            positionFrameRef.current = requestAnimationFrame(animate)
        }

        positionFrameRef.current = requestAnimationFrame(animate)

        return () => {
            if (positionFrameRef.current) cancelAnimationFrame(positionFrameRef.current)
        }
    }, [getPosition])

    // When the user is seeking a timestamp by grabbing the progress bar slider and moving the cursor, we need to
    // de-correlate the slider from the audio playing, by bypassing `setSeekPosition` in `animate`.
    // We use a ref vs a state variable to store it, in order to avoid stale closures in the animate function.
    const [seekBarPosition, setSeekBarPosition] = useState(0.0)
    const seekBarPositionFrameRef = useRef<number>()
    const isUserSeekingRef = useRef(false)
    const episodeHashid =
        audioProperties.content_type === 'episode' && 'hashid' in audioProperties ? audioProperties.hashid : null

    useEffect(() => {
        const animate = () => {
            // Keep the seek bar position in sync with the position of the audio when the user is not seeking.
            // Round position to the nearest second to avoid too many-rerenders and performance issues.
            if (isUserSeekingRef.current === false) setSeekBarPosition(Math.round(getPosition()))
            seekBarPositionFrameRef.current = requestAnimationFrame(animate)
        }

        seekBarPositionFrameRef.current = requestAnimationFrame(animate)

        return () => {
            if (seekBarPositionFrameRef.current) cancelAnimationFrame(seekBarPositionFrameRef.current)
        }
    }, [getPosition])

    // Activity pulse every 10s of uninterrupted listening.
    const postMarker = useCallback(() => {
        if (episodeHashid) {
            // Make sure most offsets correspond to 10, 20, ... (and not 9, 19, ...), hence the +1.
            const data = { episode_hashid: episodeHashid, offset: Math.floor(getPosition()) + 1 }
            axios
                .post(globals.markersListUrl, data)
                .catch((err) =>
                    captureMessage(
                        'Player activity marker could not be posted',
                        { name: 'Post Request Error', properties: { error: err } },
                        ERROR_LEVEL
                    )
                )
        }
    }, [episodeHashid])

    const [markerInterval, setMarkerInterval] = useState<ReturnType<typeof setInterval>>(null)
    useEffect(() => {
        const intervalCleanup = () => {
            clearInterval(markerInterval)
            setMarkerInterval(null)
        }

        if (isPlaying) {
            setMarkerInterval(setInterval(postMarker, 10000))
        } else {
            intervalCleanup()
        }

        return intervalCleanup
    }, [isPlaying])

    const positionContext: AudioPositionContextInterface = useMemo(
        () => ({
            duration,
            isUserSeekingRef,
            position,
            seek,
            seekBarPosition,
            setSeekBarPosition
        }),
        [duration, isUserSeekingRef, position, seek, seekBarPosition, setSeekBarPosition]
    )

    const jumpBackward = useCallback(() => {
        seek(Math.max(getPosition() - 15, 0))
    }, [getPosition, seek])

    const jumpForward = useCallback(() => {
        seek(Math.min(getPosition() + 15, duration))
    }, [duration, getPosition, seek])

    const jumpContext = useMemo<AudioJumpContextInterface>(
        () => ({
            jumpBackward,
            jumpForward
        }),
        [jumpBackward, jumpForward]
    )

    return (
        <AudioPositionContext.Provider value={positionContext}>
            <AudioJumpContext.Provider value={jumpContext}>{children}</AudioJumpContext.Provider>
        </AudioPositionContext.Provider>
    )
}
