import React, { useContext } from 'react'

import type { AlertProps } from '@mui/material/Alert'
import type { SnackbarProps } from '@mui/material/Snackbar'

import ConditionalSuspense from 'components/Template/ConditionalSuspense'
import { SnackbarContext } from 'contexts/SnackbarProvider'
import importWithRetry from 'utils/import-with-retry'

const LazyAlert = React.lazy<React.ComponentType<AlertProps>>(() =>
    importWithRetry(() => import('@mui/material/Alert'))
)
const LazySnackbar = React.lazy<React.ComponentType<SnackbarProps>>(() =>
    importWithRetry(() => import('@mui/material/Snackbar'))
)

const GENERIC_SUCCESS_MESSAGE = 'Changes saved! 🎉'
const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please contact your coordinator.'

export default function CustomSnackbar() {
    const { autoHideDuration, close, message, onClose, open, severity } = useContext(SnackbarContext)

    const handleCloseSnackbar: SnackbarProps['onClose'] = (_event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        onClose && onClose()
        close()
    }

    return (
        <ConditionalSuspense condition={open}>
            <LazySnackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={autoHideDuration}
                onClose={handleCloseSnackbar}
                open={open}
            >
                {/* Wrap the alert in a component that can accept a `ref` since the alert may still be loading when the
                    snackbar first opens. */}
                <span>
                    <LazyAlert icon={false} severity={severity} variant='filled'>
                        {
                            // Display a default message conditionally, based on the severity passed.
                            message ? message : severity === 'error' ? GENERIC_ERROR_MESSAGE : GENERIC_SUCCESS_MESSAGE
                        }
                    </LazyAlert>
                </span>
            </LazySnackbar>
        </ConditionalSuspense>
    )
}
