import { useLocation, useMatch } from 'react-router-dom'

import { TRACKING_PAGE_NAMES } from 'utils/ts-misc'

/**
 * The trailing catch-all `*` makes sure the corresponding login frames (at `.../login/`) are considered
 * as the same page type.
 * `includeLogin` must be false when called from the Authentication component since the page name is used to determine
 * if and how the user should be redirected after signing in.
 * TODO: Refactor this logic, as it's getting quite heavy.
 */
export default function usePageName(includeLogin = false): PageName {
    const location = useLocation()

    const isLogin = Boolean(location.pathname.match(/\/login\/$/))
    // Leave out the catch-all to check if the dashboard home is the current page.
    const isDashboardHome = Boolean(useMatch('/dashboard/'))
    const isDashboardLibrary = Boolean(useMatch('/dashboard/library/*'))
    const isEpisodeDetailPage = Boolean(useMatch('/creations/:episodeSlug/*'))
    const isEpisodeListPage = Boolean(useMatch('/libraries/:artifactSlug/*'))
    const isFollowUpDetailPage = Boolean(useMatch('/follow-ups/:followUpHashid/'))
    const isFollowUpRecordingPage = Boolean(useMatch('/follow-ups/:followUpHashid/*'))
    const isHolidayCardDetailPage = Boolean(useMatch('/holiday-cards/:holidayCardHashid/'))
    const isImageDetailPage = Boolean(useMatch('/creations/:episodeSlug/photos/:imageHashid/*'))
    const isImageListPage = Boolean(useMatch('/creations/:episodeSlug/photos/*'))
    const isMomentDetailPage = Boolean(useMatch('/moments/:momentSlug/'))
    const isMomentListPage = Boolean(useMatch('/creations/:episodeSlug/moments/*'))
    const isPlaylistDetailPage = Boolean(useMatch('/playlists/:playlistHashid/'))
    const isPlaylistRecordingPage = Boolean(useMatch('/playlists/:playlistHashid/new/'))
    const isPlaylistListPage = Boolean(useMatch('/playlists/'))
    const isTranscriptDetailPage = Boolean(useMatch('/creations/:episodeSlug/transcript/*'))
    const isWhatsNextPage = Boolean(useMatch('/try/whats-next/'))

    if (includeLogin && isLogin) return TRACKING_PAGE_NAMES.login
    else if (isDashboardHome) return TRACKING_PAGE_NAMES.dashboardHome
    else if (isDashboardLibrary) return TRACKING_PAGE_NAMES.dashboardLibrary
    // These will also match with the episode detail page, so these need to be checked first.
    else if (isImageDetailPage) return TRACKING_PAGE_NAMES.imageDetail
    else if (isImageListPage) return TRACKING_PAGE_NAMES.imageList
    else if (isMomentListPage) return TRACKING_PAGE_NAMES.momentList
    else if (isTranscriptDetailPage) return TRACKING_PAGE_NAMES.transcriptDetail
    else if (isEpisodeDetailPage) return TRACKING_PAGE_NAMES.episodeDetail
    else if (isEpisodeListPage) return TRACKING_PAGE_NAMES.episodeList
    else if (isFollowUpDetailPage) return TRACKING_PAGE_NAMES.followUpDetail
    else if (isFollowUpRecordingPage) return TRACKING_PAGE_NAMES.followUpRecording
    else if (isHolidayCardDetailPage) return TRACKING_PAGE_NAMES.holidayCardDetail
    else if (isMomentDetailPage) return TRACKING_PAGE_NAMES.momentDetail
    else if (isPlaylistDetailPage) return TRACKING_PAGE_NAMES.playlistDetail
    else if (isPlaylistRecordingPage) return TRACKING_PAGE_NAMES.playlistRecording
    else if (isPlaylistListPage) return TRACKING_PAGE_NAMES.playlistList
    else if (isWhatsNextPage) return TRACKING_PAGE_NAMES.whatsNext
}
