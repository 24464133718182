/**
 * TODO: this module is becoming huge, we should think of a relevant way to split it. Maybe based on necessary imports,
 * not to load dependencies for nothing on every page?
 */
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import endpoints from 'endpoints.json'
import type { AxiosError, AxiosResponse } from 'utils/network'
import axios from 'utils/network'

export const ARTIFACT_QUERY_KEY = (artifactHashid: string) => ['artifact', artifactHashid]
export const ARTIFACT_USER_ROLE_LIST_QUERY_KEY = (artifactHashid: string) => ['artifactUserRoleList', artifactHashid]
export const EPISODE_DETAIL_QUERY_KEY = (episodeSlug: string) => ['episodeDetail', episodeSlug]
export const FEATURED_EPISODES_QUERY_KEY = ['featuredEpisodes']
export const INTERVIEW_DASHBOARD_QUERY_KEY = (artifactHashid: string) => ['interviewDashboardData', artifactHashid]
export const PROFILE_QUERY_KEY = ['profile']
export const USER_QUERY_KEY = ['user']

/**
 * Handle anonymous users still getting onboarded.
 */
export const artifactDetailQuery = (artifactHashid?: string, enabled = true) => ({
    queryKey: ARTIFACT_QUERY_KEY(artifactHashid),
    queryFn: async () => axios.get<Artifact>(`${endpoints.artifactList}${artifactHashid}/`).then((res) => res.data),
    enabled: globals.userInitialData.is_authenticated && artifactHashid !== undefined && enabled
})

export const episodeDetailQuery = (episodeSlug: string, options: UseQueryOptions<Episode> = {}) => ({
    queryKey: EPISODE_DETAIL_QUERY_KEY(episodeSlug),
    queryFn: async () => fetchEpisodeDetail(episodeSlug),
    enabled: Boolean(episodeSlug),
    // Make sure the placeholder data passed from the back-end matches the slug in case of SPA navigation!
    placeholderData:
        globals.episodeDetailPlaceholderData && globals.episodeDetailPlaceholderData.slug == episodeSlug
            ? globals.episodeDetailPlaceholderData
            : undefined,
    refetchOnWindowFocus: false,
    retry: false, // No need to keep retrying, we'll manually refetch it upon login.
    ...options
})

export function fetchArtifactUserRoleList(artifactHashid: string) {
    return axios
        .get<ArtifactUserRole[]>(`${endpoints.artifactUserRoleList}?artifact__hashid=${artifactHashid}`)
        .then((res) => res.data)
}

export function fetchEpisodeDetail(episodeSlug: string) {
    // The slug is unique, which guarantees the accurate use of `[0]` within the paginated results.
    return axios
        .get<PaginatedListResponse<Episode>>(`${endpoints.episodeList}?slug=${episodeSlug}`)
        .then((res) => res.data['results'][0])
}

export function fetchPlayerActivityList(artifactHashid = '') {
    const url = artifactHashid
        ? `${endpoints.playerActivityList}?artifact_hashid=${artifactHashid}`
        : endpoints.playerActivityList
    return axios.get<UserPlayerActivity[]>(url).then((res) => res.data)
}

/**
 * Use this to fetch an up-to-date name for the Producer, instead of using the globals, as their name gets updated
 * async upon first payment!
 */
export function fetchProfile() {
    return axios.get<Profile[]>(endpoints.profileList).then((res) => {
        // Each user should only have one matching profile, take the first item.
        return res.data.length ? res.data[0] : null
    })
}

interface FetchPresignedURLOptions {
    mimeType?: string
}

export interface PresignedURLData {
    form: FormData
    key: string
    url: string
}

export function fetchPresignedURL(
    endpoint: string,
    file: Blob | File,
    { mimeType }: FetchPresignedURLOptions = {},
    onError: (error: AxiosError) => void = () => null
): Promise<PresignedURLData> {
    return axios
        .get(endpoint)
        .catch((error: AxiosError) => onError(error))
        .then((res: AxiosResponse<PresignedURLResponse>) => {
            const presignedFile = new File([file], res.data.path, { type: mimeType || file.type })
            const form = new FormData()

            Object.entries(res.data.presigned_post_parameters.fields).forEach((entry) => {
                const [key, value]: string[] = entry
                form.append(key, value)
            })

            form.append('file', presignedFile, res.data.path)
            return {
                form,
                url: res.data.presigned_post_parameters.url,
                key: res.data.presigned_post_parameters.fields.key
            }
        })
}

interface PostToPresignedURLOptions extends FetchPresignedURLOptions {
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

/**
 * Obtain a presigned upload url to upload to S3 and upload the file. Returns a promise that yields the path of the file
 * in the bucket.
 */
export function postToPresignedURL(
    endpoint: string,
    file: Blob | File,
    { onUploadProgress, ...fetchPresignedURLOptions }: PostToPresignedURLOptions = {}
) {
    return fetchPresignedURL(endpoint, file, fetchPresignedURLOptions).then((data) => {
        return axios.post(data.url, data.form, { onUploadProgress }).then(() => data.key)
    })
}

/**
 * Allow the hashid to be omitted as there is no ambiguity in the whole purchase flow, given the route.
 */
export function useArtifactDetailQuery(artifactHashid?: string, enabled = true): UseQueryResult<Artifact> {
    const params = useParams()
    if (typeof artifactHashid === 'undefined' && typeof params.artifactHashid !== 'undefined') {
        artifactHashid = params.artifactHashid
    }

    return useQuery(artifactDetailQuery(artifactHashid, enabled))
}

export function useArtifactUserRoleListQuery(
    artifactHashid: string,
    options: UseQueryOptions<ArtifactUserRole[]> = {}
) {
    const params = useParams()
    if (typeof artifactHashid === 'undefined' && typeof params.artifactHashid !== 'undefined') {
        artifactHashid = params.artifactHashid
    }

    return useQuery(
        ARTIFACT_USER_ROLE_LIST_QUERY_KEY(artifactHashid),
        () => fetchArtifactUserRoleList(artifactHashid),
        options
    )
}

export function useEpisodeDetailQuery(episodeSlug: string, options: UseQueryOptions<Episode> = {}) {
    return useQuery<Episode>(episodeDetailQuery(episodeSlug, options))
}

/**
 * Matches our InterviewDashboardSerializer, and the nested FeaturedInterviewSerializer with extra fields
 * `calendly_scheduling_url`, `interviewer` (with bio), `other_participation_invites`.
 */
export function useInterviewDashboardQuery(
    artifactHashid: string,
    options: UseQueryOptions<InterviewDashboardArtifact> = {}
) {
    return useQuery(
        INTERVIEW_DASHBOARD_QUERY_KEY(artifactHashid),
        () =>
            axios
                .get<InterviewDashboardArtifact>(`/api/v1.0/interview-dashboard-data/${artifactHashid}`)
                .then((res) => res.data),
        {
            staleTime: 0, // Otherwise the refetch on focus will be ignored.
            ...options
        }
    )
}

export function useProfileQuery() {
    return useQuery(PROFILE_QUERY_KEY, fetchProfile, { refetchOnWindowFocus: false })
}

export function useUserQuery(): UseQueryResult<User> {
    return useQuery(
        USER_QUERY_KEY,
        // Each user should only have one matching profile, take the first item.
        // N.B. For anonymous users, data can't be undefined in React Query (and actually convenient).
        () => axios.get<User[]>(endpoints.userList).then((res) => res.data[0] || {}),
        { initialData: globals.userInitialData }
    )
}
