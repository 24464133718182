/**
 * Set of constants re-used across the app.
 * N.B. `constants` is a special word, hence the need for another module name!
 */
export const LOCAL = 'Local'
export const PRODUCTION = 'Production'
export const STAGING = 'Staging'

// Now using the same div ID across all the HTML templates injecting React code.
// It's the JS tag contained in those templates that determine what the entrypoint (and therefore the app) is.
export const REACT_DIV_ID = 'react_root'

// Prevent Storybook from crashing as globals are not injected.
export const IMAGEKIT_ENDPOINT = `https://ik.imagekit.io/no6qhzlonix/${(globals.environment || LOCAL).toLowerCase()}/`
export const IMAGEKIT_PUBLIC_PREFIX = 'https://ik.imagekit.io/no6qhzlonix/production/public'
export const IMAGEKIT_ILLUSTRATION_PREFIX = `${IMAGEKIT_PUBLIC_PREFIX}/illustrations`
export const IMAGE_STACK_MAX_SIZE = 2
export const IMAGE_STACK_ANGLES = [7, -5, 8, -8, 4, -11]
export const MICROPHONE_ILLUSTRATION_SOURCE = `${IMAGEKIT_PUBLIC_PREFIX}/illustrations/microphone.svg`
export const S3_ASSETS_BUCKET_URL = 'https://heyartifact-assets.s3-us-west-2.amazonaws.com/'

export const FUNERAL_DEMO_ARTIFACT_SLUG = 'funeral-demo'

// Audio-related config.
export const AUDIO_LOAD_OPTIONS: AudioLoadOptions = {
    // Force HTML5 audio for large files to avoid waiting for the full download & decoding before playing. HTML5 also
    // helps with iOS devices by allowing audio to play even when mute mode is on.
    // N.B. In order for streamed audio content to work, make sure to specify the format of the audio.
    // @see https://github.com/E-Kuerschner/useAudioPlayer#gotcha-streaming-audio
    format: 'mp3',
    html5: true,
    initialVolume: 1.0,
    // When using HTML5 Audio, setting this to 'metadata' (instead of `true` by default) will only preload the
    // file's metadata (to get its duration without download the entire file, for example).
    // N.B. On Firefox, the audio player will stall if the whole audio file is not preloaded.
    // @see https://github.com/goldfire/howler.js/issues/1495
    // N.B. Even though `react-use-audio-player` v2 interface doesn't explicitly surface it, you can still pass the
    // `preload` option as it's still Howler.js under the hood!
    // @ts-ignore
    preload: /Firefox/i.test(navigator.userAgent) ? true : 'metadata'
}
