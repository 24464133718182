import React, { Suspense, useState } from 'react'

import type { SuspenseProps } from 'react'

import useEffectOnce from 'hooks/useEffectOnce'
import type { OptionalBy } from 'utils/ts-misc'

interface ConditionalSuspenseProps extends OptionalBy<SuspenseProps, 'fallback'> {
    condition: boolean
}

/**
 * Prevent the children from mounting until `condition` is `true`, but keeps the children mounted even if `condition`
 * becomes `false` again. Useful for lazy loaded components that have enter/exit animations (such as snackbars and the
 * audio player).
 */
export default function ConditionalSuspense({ children, condition, fallback = null }: ConditionalSuspenseProps) {
    const [hasLoaded, setHasLoaded] = useState(false)

    if (!hasLoaded && !condition) return null

    return (
        <Suspense fallback={fallback}>
            <>
                <LoadTrigger onLoad={() => setHasLoaded(true)} />
                {children}
            </>
        </Suspense>
    )
}

function LoadTrigger({ onLoad }: { onLoad: () => void }): null {
    useEffectOnce(onLoad)
    return null
}
