import React, { useEffect, useRef } from 'react'

import { useQuery } from '@tanstack/react-query'
import get from 'lodash.get'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import FallbackComponent from 'components/Error/FallbackComponent'
import { DEMO_TOUR_VARIANT_NAME } from 'pages/Listen/EpisodeDetail/Tour/constants'
import { fetchProfile, PROFILE_QUERY_KEY } from 'queries'
import axios from 'utils/network'
import { isDemoArtifact } from 'utils/ts-misc'

export const ERROR_LEVEL = 'error'
export const INFO_LEVEL = 'info'
export const WARNING_LEVEL = 'warning'

/**
 * Wrapper to capture messages manually. Sanity-check that Sentry has already been loaded (from base.html).
 */
export function captureMessage(message: string, context: Record<string, any> = {}, level = INFO_LEVEL) {
    forceSentryLoading() // Otherwise Sentry.withScope will do nothing (unlike `captureMessage` directly).
    // Use a `withScope` block (instead of `setScope`) to make sure the scope is restored to the previous values
    // after each event is sent, instead of keeping a global scope.
    typeof Sentry !== 'undefined' &&
        Sentry.withScope(function (scope: Record<string, any>) {
            if (typeof context.properties !== 'undefined') {
                const contextName = context.name || 'Custom Context'
                scope.setContext(contextName, context.properties)
            }
            Sentry.captureMessage(message, { level: level })
        })
}

/**
 * Wrapper to capture exceptions manually. Sanity-check that Sentry has already been loaded (from base.html).
 * TODO: when it becomes useful, start passing a context just like we do when capturing messages manually.
 */
export function captureException(exception: unknown) {
    typeof Sentry !== 'undefined' && Sentry.captureException(exception)
}

interface ErrorBoundaryProps {
    children: React.ReactNode
    componentDisplayName?: string
}

/**
 * Wrap the library error boundary, pass our custom fallback component and allow us to pass extra props.
 */
export function ErrorBoundary({ children, componentDisplayName }: ErrorBoundaryProps) {
    return (
        <ReactErrorBoundary
            fallbackRender={({ error }) => (
                <FallbackComponent componentDisplayName={componentDisplayName} error={error} />
            )}
        >
            {children}
        </ReactErrorBoundary>
    )
}

/**
 * Make sure Sentry is loaded (which happens automatically only in case of *unhandled* exceptions or when calling
 * `captureException/Message` directly) to make sure exceptions & messages are logged properly.
 */
export function forceSentryLoading() {
    typeof Sentry !== 'undefined' && Sentry.forceLoad()
}

/**
 * Call Amplitude `identify` methods.
 *
 * TODO: - run identify async upon logins/signups using fresh data, without relying on globals.
 *         @see https://docs-gb.cohere.so/external-integrations/segment
 */
export function identify(userHashid?: string, firstName?: string, lastName?: string, email?: string) {
    if (userHashid) {
        // Amplitude identification process.
        if (typeof amplitude !== 'undefined') {
            const identifyEvent = new amplitude.Identify()
            if (email) identifyEvent.set('email', email)
            amplitude.identify(identifyEvent)
        } else {
            // Should never happen, just a sanity-check to flag bugs before messing up analytics.
            captureMessage('`amplitude.identify` called before Amplitude was loaded.', {}, WARNING_LEVEL)
        }
    } else {
        // Should never happen, just a sanity-check to flag bugs before messing up analytics.
        captureMessage('`identify` called on null User hashid', {}, WARNING_LEVEL)
    }
}

export function getCurrentTimestamp() {
    return new Date().getTime()
}

/*
 * Wrapper to either:
 *     - mimic Amplitude `track` calls, but via our backend if the user is logged-in, more reliable.
 *     - send directly events to Amplitude for unauthenticated users, less reliable but easier for anonymous users.
 *
 * Add properties specific to page views, such as the path, search query, etc.
 */
export function page(pageName: string, pageProperties: Record<string, any> = {}) {
    const eventName = `Viewed ${pageName} Page` // Segment naming convention for translating PageViews into Events.
    // TODO: make this a hook `const page = usePage()` that implements the useUserQuery hook for an up-to-date result!
    if (globals.userInitialData.is_authenticated) {
        pageProperties['path'] = location.pathname
        pageProperties['referrer'] = document.referrer
        pageProperties['search'] = location.search
        pageProperties['title'] = document.title
        pageProperties['url'] = location.href
        postEvent(eventName, pageProperties, 'page')
    } else if (typeof amplitude !== 'undefined') {
        // Defined in base.html
        amplitude.track(eventName, pageProperties)
    } else {
        // Should never happen, just a sanity-check to flag bugs before messing up analytics.
        captureMessage('`amplitude.track` called before Amplitude was loaded.', {}, WARNING_LEVEL)
    }
}

/*
 * Generic method to post events to our API, whether they be regular interactions or page views.
 */
function postEvent(eventName: string, eventProperties: Record<string, any>, type: 'page' | 'track') {
    axios.post(globals.eventsUrl, {
        name: eventName,
        properties: eventProperties,
        type: type,
        original_timestamp: getCurrentTimestamp()
    })
}

/*
 * Wrapper to mimic Amplitude `track` calls, but via our backend.
 * Similarly to `page`, can send traffic directly to Amplitude for unauthenticated users.
 *
 * @param `forceJavascript` boolean to track the event using Amplitude JS module, instead of forwarding the event via
 * our Python backend.
 */
export function track(eventName: string, eventProperties: Record<string, any> = {}, forceJavascript = false) {
    // If the user is currently located on the listening page of a demo Artifact, do not track the event and return early.
    // Events related to the tour on demo episodes should be tracked.
    if (isDemoArtifact() && eventProperties.variant !== DEMO_TOUR_VARIANT_NAME) {
        return
    } else if (globals.userInitialData.is_authenticated && forceJavascript !== true) {
        // Defined in base.html
        postEvent(eventName, eventProperties, 'track')
    } else if (typeof amplitude !== 'undefined') {
        // Defined in base.html
        amplitude.track(eventName, eventProperties)
    } else {
        // Should never happen, just a sanity-check to flag bugs before messing up analytics.
        captureMessage('`amplitude.track` called before Amplitude was loaded.', {}, WARNING_LEVEL)
    }
}

/**
 * Wrapper used to track whenever a user logs in / signs up with a new email, and register them with our tracking
 * partners.
 * We created this wrapper instead of modifying the `useProfileQuery` hook because having the callback there would
 * cause it to be executed X times if `useProfileQuery` is used X times on the page.
 */
export function TrackingWrapper({ children }: { children: JSX.Element }) {
    // Initial call to `identify` based on globals solely.
    useEffect(() => {
        if (globals.userInitialData.hashid) identify(globals.userInitialData.hashid)
    }, [])

    const userEmail = useRef(undefined)
    useQuery(PROFILE_QUERY_KEY, fetchProfile, {
        enabled: false, // Do not execute automatically, only track changes from calls made in children components.
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            // Upon success, if the user has logged in or signed up (new email or at least different),
            // identify them across all the different tracking partners.
            const user = get(res, 'user', {} as FullUser)
            if (user.hashid && user.email && user.email !== userEmail.current) {
                identify(user.hashid, user.first_name, user.last_name, user.email)
            }
            userEmail.current = user.email
        }
    })

    return <>{children}</>
}
