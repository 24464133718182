/**
 * Refactor the logic across the calls to our the back-end using a custom instance of Axios.
 * N.B. Favor promises as they're particularly convenient with state machines.
 */

import axios, { AxiosError, AxiosResponse } from 'axios'

import { getCurrentTimestamp } from 'utils/monitoring'

const instance = axios.create()

instance.defaults.headers.common['Content-Type'] = 'application/json'
// For "unsafe" requests (PATCH, POST, etc.), pass the Django CSRF token - otherwise you'll get a 403.
instance.defaults.headers.common['X-CSRFToken'] = globals.csrfToken
// Necessary for old versions of Safari. TODO: check if 1) working fine with Axios 2) still necessary 3) safe.
instance.defaults.withCredentials = true

instance.interceptors.request.use((config) => {
    // Make sure the `page`/`track` data gets annotated with the current timestamp before submission (cf tracking README).
    if (config.method === 'post' && 'original_timestamp' in config.data) {
        // TODO: update the `sent_at` attribute when allowing requests to be retried while passing a timestamp,
        // to make sure the back-end can infer the right timestamp (received_at - sent_at + timestamp).
        config.data['request_timestamp'] = getCurrentTimestamp()
    }

    return config
})

export default instance

export { AxiosError, AxiosResponse }
