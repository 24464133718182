import { useCallback, useContext, useEffect } from 'react'

import get from 'lodash.get'
import { useGlobalAudioPlayer as useVendorAudioPlayer } from 'react-use-audio-player'

import { SILENT_AUDIO_SRC } from 'components/AudioPlayer/constants'
import { isSameSourceLoaded } from 'components/AudioPlayer/utils'
import { AUDIO_LOAD_OPTIONS } from 'config'
import { AudioPlayerContext } from 'contexts/AudioPlayerProvider'
import { captureMessage, ERROR_LEVEL, track } from 'utils/monitoring'

/**
 * Wrap the external hook provided by `react-use-audio-player` to customize its behavior.
 * @see https://github.com/E-Kuerschner/useAudioPlayer/
 */
export default function useAudioPlayer() {
    const { audioProperties, isMuted, setAudioProperties, setIsMuted, setShowPlayer, showPlayer } =
        useContext(AudioPlayerContext)

    const source = audioProperties.source

    // Warning: the one difference with Howler's interface is that we shouldn't rely on their event listeners directly.
    // Instead, use effects!
    // @see https://github.com/E-Kuerschner/useAudioPlayer#gotcha-using-event-listeners
    const {
        duration: vendorDuration,
        error,
        getPosition,
        isReady,
        load,
        mute,
        playing: isPlaying,
        seek,
        setVolume,
        stop,
        stopped: hasEnded,
        togglePlayPause: vendorTogglePlayPause,
        volume
    } = useVendorAudioPlayer()

    // N.B. When preloading the metadata, the duration is defined even before the audio starts playing!
    const duration = vendorDuration || 0.0

    useEffect(() => {
        mute(isMuted)
    }, [isMuted])

    useEffect(() => {
        if (error) {
            const errorMessage = get(error, 'message', '')
            captureMessage(
                `react-use-audio-player failed with ${errorMessage}`,
                { properties: { error: error } },
                ERROR_LEVEL
            )
        }
    }, [error])

    /**
     * Convenient helper function to load a new piece of audio only if the source is different from the current source.
     * This helper is also used to preload the audio file on page load.
     *
     * The flag `overrideAudio` determines whether it should be allowed to override the player when an existing
     * piece of audio is loaded.
     *
     * The flag `autoplay` will only have an effect if the audio gets overriden.
     *
     * N.B. Setting audio properties is not enough (at least on Firefox) to preload the metadata. Use this instead.
     */
    const loadAudioIfNeeded = (
        newAudioProperties: AudioProperties = null,
        overrideAudio = false,
        autoplay = false,
        extraAudioLoadOptions: AudioLoadOptions = {}
    ) => {
        // Only load a new audio file if none is currently loaded or if the flag `overrideAudioAndPlay` is passed.
        const isSafeLoadAudio = !source || overrideAudio === true
        if (
            newAudioProperties &&
            newAudioProperties.source &&
            !isSameSourceLoaded(source, newAudioProperties.source) &&
            isSafeLoadAudio
        ) {
            if (newAudioProperties === null) {
                captureMessage(
                    `New audio was initialized by the player, but 'newAudioProperties' is null,
                     impacting the analytics.`,
                    {},
                    ERROR_LEVEL
                )
            }

            setAudioProperties(newAudioProperties)

            load(newAudioProperties.source, { autoplay: autoplay, ...extraAudioLoadOptions, ...AUDIO_LOAD_OPTIONS })

            if (autoplay) {
                trackPlayerEvent('Player Started', newAudioProperties)
            }

            return true
        }

        return false
    }

    // Wrap conveniently calls to handlePlay/handlePause and make the right one based on the current state.
    const togglePlayPause = useCallback(
        (newAudioProperties?: AudioProperties) => {
            const newAudioLoaded = loadAudioIfNeeded(newAudioProperties, true, true)

            // Since `.loadAudioIfNeeded` was called with `overrideAudioAndPlay = true`, the play action was already executed.
            if (newAudioLoaded === false) {
                // Avoid race conditions (state updates) and assess which action was taken *before* taking it.
                const eventName = isPlaying ? 'Player Paused' : 'Player Started'
                vendorTogglePlayPause()
                // If the PlayPauseIconButton in the player is pressed, no new audio properties are passed. Use the state.
                trackPlayerEvent(eventName, newAudioProperties || audioProperties)
            }
        },
        [isPlaying, vendorTogglePlayPause]
    )

    // Stop and close player, for instance when unmounting the InterviewWizard.
    const stopAndClose = () => {
        stop()
        setShowPlayer(false)
    }

    /**
     * Expose a way to jump to a specific timestamp.
     * @param {timestamp} Timestamp in seconds.
     * @param {play} Whether or not to start playing if currently paused.
     */
    const handleJumpToTimestamp = (timestamp: number, play: boolean) => {
        // The audio starts playing nearly instantaneously at the correct timestamp, but the seekbar might take a
        // second to catch up.
        // TODO: Find a way to update the seekbar right when the player starts.
        seek(timestamp)
        if (!isPlaying && play) togglePlayPause()
    }

    /**
     * react-use-audio-player does not surface an unload method or the underlying Howler instance, so for now load in a
     * silent audio file to mimic an unload feature.
     * @see https://github.com/E-Kuerschner/useAudioPlayer/issues/124
     */
    const unload = () => {
        const newAudioProperties: AudioProperties = {
            content_type: audioProperties.content_type,
            source: SILENT_AUDIO_SRC,
            title: ''
        }
        loadAudioIfNeeded(newAudioProperties, true, false)
    }

    return {
        audioProperties,
        duration,
        error,
        getPosition,
        handleJumpToTimestamp,
        hasEnded,
        isMuted,
        isPlaying,
        isReady,
        loadAudioIfNeeded,
        seek,
        setIsMuted,
        setShowPlayer,
        setVolume,
        showPlayer,
        source,
        stopAndClose,
        togglePlayPause,
        unload,
        volume
    }
}

/**
 * Util making sure the source is always filtered out before passing data to Amplitude, for privacy purposes, till
 * we improve all S3 bucket settings.
 */
function trackPlayerEvent(eventName: string, audioProperties: AudioProperties) {
    const { ...eventProperties } = audioProperties
    if (eventProperties.source) delete eventProperties['source']
    track(eventName, eventProperties)
}
