import * as EmailValidator from 'email-validator'
import get from 'lodash.get'

import { areSetsEqual, displayNamesList } from 'utils/ts-misc'

export const COORDINATOR_EMAIL = 'coordinator@heyartifact.com'
// TODO: Merge these two constants once HEIC/HEIF images can be previewed in the browser.
export const PREVIEWABLE_INPUT_IMAGE_EXTENSIONS = 'image/jpeg, image/jpg, image/png'
export const INPUT_IMAGE_EXTENSIONS = `image/heic, image/heif, video/quicktime, video/mp4, ${PREVIEWABLE_INPUT_IMAGE_EXTENSIONS}`

export function getOrdinal(number) {
    if (number > 3 && number < 21) return 'th'
    switch (number % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

export function groupBy(list, keyGetter) {
    const result = {}
    list.map((item) => {
        const key = keyGetter(item)
        if (!result[key]) result[key] = []
        result[key].push(item)
    })
    return result
}

export function pascalToSnake(text) {
    if (text) {
        return (
            text[0].toLowerCase() + text.slice(1, text.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
        )
    } else {
        return ''
    }
}

export function range(lowerBound, upperBound) {
    return [...Array(upperBound - lowerBound).keys()].map((i) => parseInt(i) + parseInt(lowerBound))
}

/**
 * Capitalize the first letter. Catch undefined variables gracefully.
 * N.B. Unlike Python `.title()` method, only the very first letter of the whole string gets capitalized!
 */
export function toTitleCase(text) {
    if (typeof text !== 'string') {
        return ''
    }
    return text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
}

/**
 * Convert any string to kebab-case.
 */
export function toKebabCase(text) {
    if (text) {
        return text
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x) => x.toLowerCase())
            .join('-')
    } else {
        return ''
    }
}

/**
 * Convert string from snake case to camel case.
 */
export function snakeToCamelCase(str) {
    return snakeToCamelOrPascalCase(str, true)
}

/**
 * Common logic to the conversion of snake case to camel/Pascal case.
 */
export function snakeToCamelOrPascalCase(str, useCamelCase = true) {
    if (typeof str !== 'string') {
        return ''
    }
    return str
        .split('_')
        .map((word, wordIndex) =>
            wordIndex === 0 && useCamelCase ? word : word.slice(0, 1).toUpperCase() + word.slice(1, word.length)
        )
        .join('')
}

/**
 * Convert string from snake case to Pascal case.
 */
export function snakeToPascalCase(str) {
    return snakeToCamelOrPascalCase(str, false)
}

export function displayDollars(amount, digits) {
    return digits ? `$${amount.toFixed(digits)}` : `$${Math.round(amount)}`
}

export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha || alpha === 0) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

/**
 * Return the viewport size. At least one of the attributes looked-up should be defined across different web browsers.
 */
export function getDeviceDimensions() {
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientheight
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    return { height: height, width: width }
}

/**
 * Pre-process the artifact metadata at the end of the Onboarding before passing it to the backend.
 *
 * One reason for that is to double-check its keys as we're releasing a new version of the creation flow that removes
 * steps (subject, occasion text field) and adds new steps (event, inspirations). Those changes make the old local
 * storage structure outdated and not compatible.
 * TODO: we could also validate furthermore (by making sure all the tones booleans are passed, etc.)
 *
 * September 22 2020 Update: We don't store inspirations in the metadata anymore
 */
export function cleanArtifactMetadata(artifactMetadata) {
    const validKeys = ['details', 'event_based', 'occasion_type', 'tones']
    let cleanMetadata = {}
    for (let key of validKeys) {
        if (Object.keys(artifactMetadata).includes(key)) {
            cleanMetadata[key] = artifactMetadata[key]
        }
    }
    return cleanMetadata
}

export function truncateWithEllipsis(input, length) {
    if (!input) return ''

    return input.length > length ? `${input.substring(0, length)}...` : input
}

/**
 * Helper returning the title of the interview customized for the user.
 * Structure: {My | Assisted guest} {1st} interview with {other guests names}
 * @param {obj} artifact
 * @param {obj} interview
 * @returns string
 */
export function getInterviewTitle(artifact, interview) {
    // Here main guests refers to the guests handled by the current user (the user itself as a guest or any facilitatee)
    const sameMainGuestsInterviews = artifact.interviews.filter((i) =>
        areSetsEqual(
            new Set(i.user_participation_invites.map((p) => p.id)),
            new Set(interview.user_participation_invites.map((p) => p.id))
        )
    )
    const interviewDisplayNumber =
        sameMainGuestsInterviews.length > 1
            ? sameMainGuestsInterviews.findIndex((i) => i.hashid == interview.hashid) + 1
            : null
    const isGuest = interview.user_participation_invites.some((p) => p.current_user_role == 'guest')
    const mainGuestsNames = interview.user_participation_invites
        .map((p) => {
            if (p.current_user_role == 'guest') {
                return interview.user_participation_invites.length > 1 ? 'my' : 'My'
            } else {
                return p.guest_first_name
            }
        })
        .sort((name1, name2) => (name1 == 'my' ? 1 : name2 == 'my' ? -1 : 0))
    let otherGuestsNames = interview.other_participation_invites.map((g) => g.guest_first_name)

    const titleParts = []
    // {My | Assisted guest} {1st} interview with {other guests names}
    // ---------------------
    titleParts.push(`${displayNamesList(mainGuestsNames)}${!isGuest ? "'s" : ''}`)
    // {My | Assisted guest} {1st} interview with {other guests names}
    //                       -----
    if (interviewDisplayNumber) titleParts.push(`${interviewDisplayNumber}${getOrdinal(interviewDisplayNumber)}`)
    // {My | Assisted guest} {1st} interview with {other guests names}
    //                             ---------
    titleParts.push('interview')
    // {My | Assisted guest} {1st} interview with {other guests names}
    //                                       -------------------------
    if (otherGuestsNames.length > 0) titleParts.push(`with ${displayNamesList(otherGuestsNames)}`)
    return titleParts.join(' ')
}

/**
 * Helper to obtain a display name for the interview, using participation invites, customized for the producer.
 */
export function getProducerInterviewTitle(participationInvites, producerEmail) {
    const guestNames = participationInvites
        .filter((p) => get(p, 'guest.email', '') != producerEmail)
        .map((p) => p.guest_first_name)
    if (participationInvites.some((p) => get(p, 'guest.email', '') == producerEmail)) {
        guestNames.push('Me')
    }
    return `Interview with ${displayNamesList(guestNames, true)}`
}

/**
 * Split the input string if necessary to allow inputs such as "Moncef Biaz <moncef@heyartifact.com>, Martin Gouy
 * <martin@heyartifact.com>".
 *
 * N.B. For simplicity, if many potential emails are found, validate all of them or reject all of them at once,
 * instead of extracting only a part of them.
 */
export function matchEmails(input) {
    if (!input) return []

    let potentialEmails
    if (input.includes('<') && input.includes('>')) {
        // Safari doesn't support "lookbehind" regex yet. Meanwhile, manually extract the content between the <>.
        const regex = /<(.+?)>/g
        potentialEmails = input.match(regex).map((string) => string.substring(1, string.length - 1))
    } else {
        potentialEmails = [input]
    }

    if (potentialEmails.every((email) => EmailValidator.validate(email))) {
        return potentialEmails
    } else {
        return []
    }
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max)
}
