import { useContext } from 'react'

import { AudioPlayerContext, INITIAL_AUDIO_PROPERTIES } from 'contexts/AudioPlayerProvider'

/**
 * Make it convenient to override metadata linked with the audio playing in a very light hook, without consuming
 * `useAudioPlayer`.
 */
export default function useAudioProperties() {
    const { audioProperties, setAudioProperties } = useContext(AudioPlayerContext)

    // Convenient from a moment detail to a listen page, to make sure the episode too can be preloaded, and the audio
    // player shown.
    const resetAudioProperties = () => setAudioProperties({ ...INITIAL_AUDIO_PROPERTIES })

    const episodeSlug = audioProperties.content_type === 'episode' ? audioProperties.slug : null

    return { audioProperties, episodeSlug, resetAudioProperties, setAudioProperties }
}
