// Subset of the Python statuses actually used in the front-end.
// TODO: merge with Python statuses (into a JSON?) to avoid potential conflicts.
export const ARTIFACT_CREATED = 'artifact_created'
export const PAYMENT_COMPLETED = 'payment_completed'
export const PARTICIPANTS_INVITES_READY = 'participants_invites_ready'

export const INTERVIEW_CREATED = 'interview_created'
export const INTERVIEW_INSTRUCTIONS_SENT = 'interview_instructions_sent'
export const INTERVIEW_CANCELED = 'interview_canceled'
export const INTERVIEW_SCHEDULED = 'interview_scheduled'
export const INTERVIEW_COMPLETED = 'interview_completed'
export const INTERVIEW_READY_FOR_EDIT = 'interview_ready_for_edit'
export const INTERVIEW_EDIT_CLAIMED = 'interview_edit_claimed'
export const INTERVIEW_READY_FOR_POLISH = 'interview_ready_for_polish'
export const EPISODE_POLISH_CLAIMED = 'episode_polish_claimed'
export const EPISODE_POLISHED = 'episode_polished'
export const EPISODE_DELIVERED = 'episode_delivered'
export const EPISODE_RELEASED = 'episode_released'

export const DELIVERED_STATUSES = [EPISODE_DELIVERED, EPISODE_RELEASED]
export const POLISHED_STATUSES = [EPISODE_POLISHED, ...DELIVERED_STATUSES]
export const EDITED_STATUSES = [INTERVIEW_READY_FOR_POLISH, EPISODE_POLISH_CLAIMED, ...POLISHED_STATUSES]
export const COMPLETED_STATUSES = [
    INTERVIEW_COMPLETED,
    INTERVIEW_READY_FOR_EDIT,
    INTERVIEW_EDIT_CLAIMED,
    ...EDITED_STATUSES
]
export const SCHEDULED_STATUSES = [INTERVIEW_SCHEDULED, ...COMPLETED_STATUSES]
