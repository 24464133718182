import React from 'react'

import get from 'lodash.get'
import { isRouteErrorResponse, Navigate, useRouteError } from 'react-router-dom'

import FallbackComponent from 'components/Error/FallbackComponent'
import { captureMessage, ERROR_LEVEL } from 'utils/monitoring'

/**
 * Handle errors caught by React Router (earlier than the SentryErrorBoundary).
 */
export default function ErrorBoundary() {
    const error = useRouteError()

    // If the Artifact query returns an error (for example if the user is not the producer), redirect the user to their
    // dashboard.
    const requestedUrl = get(error, 'response.config.url', '')
    const responseStatus = get(error, 'response.status', null)
    if (responseStatus === 404 && requestedUrl.startsWith('/api/v1.0/artifacts/')) {
        captureMessage(
            `ArtifactDetailOutlet: ${(error as Error).message}`,
            {
                name: 'Artifact Controller Error',
                properties: { url: requestedUrl }
            },
            ERROR_LEVEL
        )
        return <Navigate replace={true} to='/dashboard/' />
    } else {
        captureMessage(
            `${error} caught at the router level`,
            {
                name: 'Router Error',
                properties: { error }
            },
            ERROR_LEVEL
        )

        const name = isRouteErrorResponse(error)
            ? // RouteNotFoundError leads to a specific component variant.
              error.status === 404
                ? 'RouteNotFoundError'
                : 'RouteErrorResponse'
            : 'Error'

        return <FallbackComponent error={{ name, message: '' }} />
    }
}
