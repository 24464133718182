/**
 * Common React root rendered and injected in the HTML, following the same setup as `create-react-app`.
 *
 * Warning: avoid importing too many modules specific to only one entrypoint, for performance purposes.
 * @see https://reactjs.org/docs/code-splitting.html
 */
import React, { Suspense } from 'react'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { IKContext } from 'imagekitio-react'
import get from 'lodash.get'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

// Make our shared utility styles available across the app.
import '../sass/main.scss'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import RouterErrorBoundary from 'components/Error/RouterErrorBoundary'
import Snackbar from 'components/Template/Snackbar'
import { SpinnerSuspense } from 'components/Template/Spinner'
import { IMAGEKIT_ENDPOINT, LOCAL } from 'config'
import AudioPlayerProvider from 'contexts/AudioPlayerProvider'
import AudioPositionProvider from 'contexts/AudioPositionProvider'
import MomentSwiperProvider from 'contexts/MomentSwiperProvider'
import SitbackProvider from 'contexts/SitbackProvider'
import { SnackbarProvider } from 'contexts/SnackbarProvider'
import { routes } from 'entrypoints/routes'
import ReactQueryDevTools from 'pages/DevTools/ReactQueryDevTools'
import { getTheme } from 'theme'
import importWithRetry from 'utils/import-with-retry'
import { ErrorBoundary, TrackingWrapper } from 'utils/monitoring'

const LazyAuthentication = React.lazy(() => importWithRetry(() => import('pages/Listen/Authentication/Authentication')))
const LazyEpisodeList = React.lazy(() => importWithRetry(() => import('pages/EpisodeList/EpisodeList')))
const LazyLibraryPageFooter = React.lazy(() =>
    importWithRetry(() => import('components/Layout/Footer/LibraryPageFooter'))
)

const IS_LOCAL = globals.environment === LOCAL

const LayoutBase = ({ footer }: { footer?: React.ReactNode }) => (
    <>
        <div className='content'>
            <Outlet />
            <Suspense fallback={null}>
                <Snackbar />
            </Suspense>
        </div>
        {footer}
        <AudioPlayer />
    </>
)

/*
 * Setup bug monitoring & tracking, then render the app passed.
 */
export default function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                cacheTime: 1000 * 60 * 60 * 24, // Persistence duration in milliseconds, aka: 1 day.
                // The default number of retries is 3. But no need to retry on a 403/4!
                // TODO: struggled to find the right types using ReactQuery documentation. Fix asap — but converting
                // this file to Typescript is already a net positive.
                // @ts-ignore
                retry: (failureCount: number, error: unknown) =>
                    [403, 404].includes(get(error, 'response.status', null)) ? false : 3,
                staleTime: 60 * 1000 // Time before refetch in milliseconds, aka: 1min.
            }
        }
    })

    let webVitals = null
    if (IS_LOCAL) {
        const LazyWebVitals = React.lazy(() => importWithRetry(() => import('pages/DevTools/WebVitals')))
        webVitals = (
            <Suspense fallback={null}>
                <LazyWebVitals />
            </Suspense>
        )
    }

    const router = createBrowserRouter([
        // TODO: move these into the `routes` module, for clarity.
        {
            element: (
                <SitbackProvider>
                    <MomentSwiperProvider>
                        <Outlet />
                    </MomentSwiperProvider>
                </SitbackProvider>
            ),
            children: [
                {
                    element: <LayoutBase />,
                    children: routes(queryClient),
                    errorElement: <RouterErrorBoundary />
                },
                // Make sure the Artifact libraries route is defined after the sports libraries route.
                {
                    element: (
                        <LayoutBase
                            footer={
                                <Suspense fallback={null}>
                                    <LazyLibraryPageFooter />
                                </Suspense>
                            }
                        />
                    ),
                    path: 'libraries/:artifactSlug',
                    children: [
                        {
                            path: 'login',
                            element: (
                                <SpinnerSuspense>
                                    <LazyAuthentication />
                                </SpinnerSuspense>
                            )
                        },
                        {
                            path: '',
                            element: (
                                <SpinnerSuspense>
                                    <LazyEpisodeList />
                                </SpinnerSuspense>
                            )
                        }
                    ],
                    errorElement: <RouterErrorBoundary />
                }
            ]
        }
    ])

    return (
        <ErrorBoundary>
            {webVitals}
            <QueryClientProvider client={queryClient}>
                <TrackingWrapper>
                    <>
                        <IKContext urlEndpoint={IMAGEKIT_ENDPOINT}>
                            <SnackbarProvider>
                                {/* TODO: finish migrating all the entrypoints to the new theme (first defined in Authentication). */}
                                <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={getTheme()}>
                                        <AudioPlayerProvider>
                                            <AudioPositionProvider>
                                                <RouterProvider router={router} />
                                            </AudioPositionProvider>
                                        </AudioPlayerProvider>
                                    </ThemeProvider>
                                </StyledEngineProvider>
                            </SnackbarProvider>
                        </IKContext>
                        <ReactQueryDevTools />
                    </>
                </TrackingWrapper>
            </QueryClientProvider>
        </ErrorBoundary>
    )
}
