/**
 * Make the browser refetch assets that failed to load, for any reason (most likely a network error), up to a given
 * amount of times, and after a tiny delay (in milliseconds).
 *
 * TODO: Revise this function to allow for the component types to be included with the returned component.
 * As a workaround, assign the components props with: `React.lazy<React.ComponentType<PROPS_INTERFACE_HERE>>( ... )`.
 */
export default async function importWithRetry(importFunction, retries = 2, interval = 2000) {
    try {
        return await importFunction()
    } catch (error) {
        if (retries) {
            await wait(interval)
            return importWithRetry(importFunction, retries - 1, interval)
        } else {
            throw error
        }
    }
}

function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
