export const DEFAULT_TOUR_VARIANT_NAME = 'default'
export const DEMO_TOUR_VARIANT_NAME = 'demo'

export const HIGHLIGHTS_TOUR_STEP = 'highlights-tour-step'
export const LISTEN_DEMO_TOUR_STEP = 'listen-demo-tour-step'
export const PHOTOS_DEMO_TOUR_STEP = 'photos-demo-tour-step'
export const PHOTOS_TOUR_STEP = 'photos-tour-step'
export const REACTIONS_TOUR_STEP = 'reactions-tour-step'
export const RELEASE_TOUR_STEP = 'release-tour-step'
export const SAMPLE_VIDEO_ID = 'sample-highlight-video'
export const SHARE_DEMO_TOUR_STEP = 'share-demo-tour-step'
