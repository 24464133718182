/**
 * General purpose utils.
 * TODO: migrate all utils from `utils.js` to this file and rename this file.
 */
import type { CSSObject } from '@emotion/serialize'
import type { InputBaseComponentProps, TextFieldProps } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import get from 'lodash.get'
import type { FieldErrors, RegisterOptions } from 'react-hook-form'

import { S3_ASSETS_BUCKET_URL } from 'config'
import type { SocialProvider } from 'pages/Authentication/hooks'
import { COMPLETED_STATUSES } from 'pages/Statuses/statuses'

// Helper function to assert that the values in an object match a given type, but without losing information about which
// keys are available.
// For example, using a type of Record<string, PageName> tells TypeScript that any/every key will return a PageName
// (assuming the key is a string). We won't get type warnings if we try to use a key that isn't defined in this case.
// TODO: Deprecate and use the `satisfies` operator introduced in TypeScript 4.9. Note that the operator introduces some
// issues with Babel, and may cause tests to fail.
// @see https://stackoverflow.com/a/70994696 for more info on this helper util.
// @see https://github.com/babel/babel/issues/15067 for the related issue in Babel.
const satisfiesRecord =
    <T>() =>
    <K extends PropertyKey>(record: Record<K, T>) =>
        record

export const TRACKING_PAGE_NAMES = satisfiesRecord<PageName>()({
    dashboardHome: 'dashboard_home',
    dashboardLibrary: 'dashboard_library',
    episodeDetail: 'episode_detail',
    episodeList: 'episode_list',
    followUpRecording: 'follow_up_recording',
    followUpDetail: 'follow_up_detail',
    holidayCardDetail: 'holiday_card_detail',
    holidayCardUnboxing: 'holiday_card_unboxing',
    imageDetail: 'image_detail',
    imageList: 'image_list',
    interviewConfirmation: 'interview_confirmation',
    interviewerBioDialog: 'interviewer_bio_dialog',
    login: 'login',
    momentDetail: 'moment_detail',
    momentList: 'moment_list',
    playlistDetail: 'playlist_detail',
    playlistList: 'playlist_list',
    playlistRecording: 'playlist_recording',
    transcriptDetail: 'transcript_detail',
    tbybLifeStoryOnboarding: 'tbyb_life_story_onboarding',
    whatsNext: 'whats_next'
})

export function areSetsEqual(a: Set<number | string>, b: Set<number | string>): boolean {
    return a.size === b.size && Array.from(a).every((value) => b.has(value))
}

/**
 * Make it easier to interface with the built-in URLSearchParams API.
 */
export function buildURLWithSearchParams(path: string, params: QueryParams): string {
    const url = new URL(path, window.location.origin)
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, value.toString())
    })
    return url.toString()
}

export function countUniqueParticipationInvites(interviews: Partial<Interview>[]): number {
    const uniqueInvites = extractUniqueParticipationInvites(interviews)
    return uniqueInvites.length
}

export function extractUniqueParticipationInvites(interviews: Partial<Interview>[]): ParticipationInvite[] {
    const uniqueInvites: Record<number, ParticipationInvite> = {}
    for (const interview of interviews) {
        for (const invite of interview.participation_invites) {
            uniqueInvites[invite.id] = invite
        }
    }
    return Object.values(uniqueInvites)
}

/**
 * Util to format a list of names into a unique string.
 * ['Moncef', 'Martin', 'Jack'] -> 'Moncef, Martin and Jack'.
 */
export function displayNamesList(nameList: string[], useAmpersand = false) {
    if (nameList.length < 1) {
        return ''
    } else if (nameList.length < 2) {
        return nameList.join('')
    } else {
        // N.B. 'You' should never be capitalized, unless at the beginning of the string.
        for (let index = 0; index < nameList.length; index++) {
            if (index > 0 && nameList[index] === 'You') nameList[index] = 'you'
        }
        return `${nameList.slice(0, -1).join(', ')} ${useAmpersand ? '&' : 'and'} ${nameList.slice(-1)}`
    }
}

/**
 * Hint at the format with country code by prepending a +1 whenever it's missing, assuming most cases should be
 * domestic ones.
 */
export function formatPhoneNumber(phoneNumber: string) {
    return phoneNumber && !phoneNumber.startsWith('+') ? '+1' + phoneNumber : phoneNumber
}

/**
 * In some cases, the back-end returns None instead of directly serving a placeholder, to allow us to identify
 * episodes having a user-generated cover image. Hence the need for the front-end to be able to still generate a
 * placeholder downstream.
 */
export function getImagePlaceholder(hashid: string) {
    // To make sure users see the same color over time for a given Artifact, just sum the char codes and get the
    // modulo 4 to pick 1 of o the 4 placeholders we have!
    const charCodes = Array.from(hashid).map((char) => char.charCodeAt(0))
    const index = (charCodes.reduce((a, b) => a + b, 0) % 4) + 1 // Placeholders are 1-indexed.
    return `${S3_ASSETS_BUCKET_URL}cover-images/fallback-v1.95.0-${index}.svg`
}

export function getUserFullName(user: Pick<User, 'first_name' | 'last_name'>, obfuscate = false): string {
    const lastName = get(user, 'last_name', '')
    return `${get(user, 'first_name', '')} ${obfuscate && lastName ? `${lastName[0]}.` : lastName}`.trim()
}

/**
 * To make this util more general and usable from the producer's perspective, but also the guests', do not compare
 * the invite emails to the current user's, but rather the Artifact producer's one.
 */
export function isProducerFeatured(artifact: Partial<Artifact>): boolean {
    let result = false
    for (const interview of artifact.interviews) {
        for (const invite of interview.participation_invites) {
            if (get(invite, 'guest.email', '') === artifact.producer.email) result = true
        }
    }
    return result
}

/**
 * Refactor the logic to map the output from `react-hook-form`'s register function to the props expected by MUI.
 * Allow extra input props to be passed e.g., to flag the text field as read-only in certain conditions.
 */
export function registerTextField(
    register: RegisterFunction,
    name: string,
    options: RegisterOptions,
    extraInputProps: InputBaseComponentProps = {}
) {
    const { ref: inputRef, ...inputProps } = register(name, options)
    return { inputRef, inputProps: { ...inputProps, ...extraInputProps } }
}

/**
 * Share the logic to generate the props across almost all our TextField instances.
 */
export function textFieldCommonProps(name: string, errors: FieldErrors): TextFieldProps {
    const error = get(errors, name, null)
    const hasError = error !== null
    return {
        error: hasError ? true : false,
        fullWidth: true,
        helperText: hasError ? error.message : '',
        size: 'small',
        variant: 'outlined'
    }
}

/**
 * Util to generate a unique string ID based on the current time and some random number.
 * Note: This util could create collisions in extreme cases (called multiple times within a millisecond).
 * However, it is more than enough for our use cases and allows us to not install an extra library.
 */
export const generateUniqueId = () => {
    const dateString = Date.now().toString(36)
    const randomness = Math.random().toString(36).substring(2)
    return dateString + randomness
}

/**
 * A helper type to assist with styled components.
 * The interface for the component's props can be included by using the generic parameter. If this interface is not
 * included, `theme` will be the only key typed in the `props` object.
 */
export type CreateCSSPropertiesWithTheme<ComponentProps = Record<string, never>> = (
    props: ComponentProps & { theme: Theme }
) => CSSObject

/**
 * A helper type that makes one or more properties of a type optional.
 * This example returns a type similar to `TextFieldProps` but `className` and `style` are optional:
 *     `OptionalBy<TextFieldProps, 'className' | 'style'>`
 */
export type OptionalBy<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/**
 * Helper to help assess if the user is currently on an Artifact demo library / listening page.
 */
export function isDemoArtifact() {
    const demoArtifactSlugs: string[] = globals.demoArtifactSlugs || []
    const demoEpisodeSlugs: string[] = globals.demoEpisodeSlugs || []
    const path = window.location.pathname || ''
    const artifactRegex = /\/libraries\/(.+)\//.exec(path)
    const artifactSlug = artifactRegex ? artifactRegex[1] : null
    const episodeRegex = /\/creations\/(.+)\//.exec(path)
    const episodeSlug = episodeRegex ? episodeRegex[1] : null

    if (artifactSlug) {
        return demoArtifactSlugs.some((slug) => slug === artifactSlug)
    } else if (episodeSlug) {
        return demoEpisodeSlugs.some((slug) => slug === episodeSlug)
    } else {
        return false
    }
}

/**
 * Util to redirect to a different pathName when `?next=` is provided as a querystring in the default path.
 * Otherwise, fallback by redirecting to the default path.
 * Allowing passing an extra search param (as a tuple (key, value)) when hitting the next path.
 */
export function redirectFromQueryString(
    defaultPath: string,
    extraURLSearchParam: { key: string; value: string } = null
) {
    const searchParams = new URLSearchParams(window.location.search)
    const newPath = searchParams.get('next') || defaultPath
    const newURL = new URL(newPath, window.location.href)
    if (extraURLSearchParam) {
        const newURLSearchParams = new URLSearchParams(newURL.search)
        const { key, value } = extraURLSearchParam
        newURLSearchParams.append(key, value)
        newURL.search = newURLSearchParams.toString()
    }
    window.location.href = newURL.href
}

/**
 * Make sure we forward the GET parameters after a redirection, and that we handle relative pathnames.
 * Can be used for relative URLs to work with React Router, or with absolute URLs.
 */
export function redirectWithQueryString(destination: string) {
    const isAbsolute = /(https:\/\/|http:\/\/)/.test(destination)

    const newUrlPrefix = isAbsolute ? '' : `${window.location.protocol}//${window.location.host}`
    const newUrl = new URL(newUrlPrefix + destination)
    const newParams = new URLSearchParams(newUrl.search)

    const oldParams = new URLSearchParams(window.location.search)
    oldParams.forEach((value, key) => {
        newParams.append(key, value)
    })

    newUrl.search = newParams.toString()
    return isAbsolute ? newUrl.href : `${newUrl.pathname}${newUrl.search}`
}

/**
 * The global URL value sometimes includes the `next` param already. The `next` argument here should override that value
 * instead of adding a second `next` param to the URL.
 */
export function redirectToSocialProvider(provider: SocialProvider, next: string = null) {
    let url: URL
    if (provider == 'google') {
        url = new URL(globals.googleLoginUrl, window.location.href)
    } else if (provider == 'facebook') {
        url = new URL(globals.facebookLoginUrl, window.location.href)
    } else if (provider == 'apple') {
        url = new URL(globals.appleLoginUrl, window.location.href)
    }
    if (next) {
        url.searchParams.set('next', next)
    }
    return () => (window.location.href = url.toString())
}

/**
 * Fetch the latest TBYB Life Story Artifact from the users studio home Artifact list. Useful in two cases:
 * - Displaying the correct Artifact's info in the post-creation page.
 * - Knowing if we need to redirect the user from the dashboard to the post-creation page.
 */
export function getLatestTBYBLifeStoryArtifact(artifacts: StudioHomeArtifact[] = []) {
    return artifacts.find(
        (a) =>
            a.is_producer &&
            !a.paid &&
            a.interviews.length === 1 &&
            a.interviews[0].is_life_story &&
            a.interviews[0].is_try_before_you_buy &&
            !COMPLETED_STATUSES.includes(a.interviews[0].status)
    )
}

/**
 * Make sure we return an empty string for extension-less files, and that empty strings are also handled properly.
 */
export function getFileExtension(filename: string): string {
    return filename ? filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2).toLocaleLowerCase() : ''
}

/**
 * Split a file name into its name/identifier and the extension.
 */
export function splitFileName(filename: string): string[] {
    const extension = getFileExtension(filename)
    const name = extension ? filename.slice(0, -1 * (extension.length + 1)) : filename || ''
    return [name, extension]
}
