import { useEffect } from 'react'

import type { EffectCallback } from 'react'

/**
 * A hook for when an effect should only run once when the component mounts. The motivation for this hook is to provide
 * a means to trigger an effect once and only once without raising the `react-hooks/exhaustive-deps` linting error
 * throughout the app.
 */
export default function useEffectOnce(callback: EffectCallback) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, [])
}
