import React, { Suspense, useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { SitbackContext } from 'contexts/SitbackProvider'
import useAudioPlayer from 'hooks/useAudioPlayer'
import useAudioProperties from 'hooks/useAudioProperties'
import usePageName from 'hooks/usePageName'
import styleConstants from 'theme/constants.json'
import importWithRetry from 'utils/import-with-retry'
import { TRACKING_PAGE_NAMES } from 'utils/ts-misc'

const LazyAudioPlayerContent = React.lazy(() =>
    importWithRetry(() => import('components/AudioPlayer/AudioPlayerContent'))
)

const PAGES_WITH_PLAYER_HIDDEN: PageName[] = [
    TRACKING_PAGE_NAMES.dashboardLibrary,
    TRACKING_PAGE_NAMES.followUpDetail,
    TRACKING_PAGE_NAMES.followUpRecording,
    TRACKING_PAGE_NAMES.login,
    TRACKING_PAGE_NAMES.playlistList,
    TRACKING_PAGE_NAMES.whatsNext
]

/**
 * Mitigate the performance impact of the AudioPlayer being always defined in the app tree by only loading its content
 * once it's meant to appear.
 */
export default function AudioPlayer() {
    const { episodeSlug } = useParams<{ episodeSlug: string }>()
    const { audioProperties, isPlaying, setShowPlayer, showPlayer, stopAndClose } = useAudioPlayer()
    const { resetAudioProperties } = useAudioProperties()
    const { setIsSitbackActive } = useContext(SitbackContext)
    const pageName = usePageName(true)

    // While keeping the audio running when exploring e.g., in the library page, make sure to stop it and clear
    // the data once landing on a distinct episode detail page! That prevents confusing flows such as creating moments
    // related to the previous episode, etc.
    useEffect(() => {
        if (episodeSlug && audioProperties.slug !== episodeSlug) {
            setIsSitbackActive(false)
            stopAndClose()
            resetAudioProperties()
        }
    }, [episodeSlug])

    // Show the audio player when the user starts playing audio, except in the gallery or if the audio is a moment.
    useEffect(() => {
        if (
            isPlaying &&
            !showPlayer &&
            audioProperties.content_type !== 'moment' &&
            audioProperties.content_type !== 'follow_up' &&
            episodeSlug == audioProperties.slug && // Otherwise, we actually want to close it on a new listen page!
            !PAGES_WITH_PLAYER_HIDDEN.includes(pageName)
        )
            setShowPlayer(true)
    }, [isPlaying, pageName, setShowPlayer, showPlayer])

    // Hide the audio player on the login page and when the user opens the gallery.
    useEffect(() => {
        if (showPlayer && PAGES_WITH_PLAYER_HIDDEN.includes(pageName)) setShowPlayer(false)
    }, [pageName, setShowPlayer, showPlayer])

    // Force the margin to be added to the bottom of the page, even if the audio player isn't shown. The main reason is
    // that we want the image detail page to have consistent bottom margin regardless of whether the player is visible.
    const forceShowMargin = [TRACKING_PAGE_NAMES.imageDetail, TRACKING_PAGE_NAMES.imageList].includes(pageName)

    return (
        <>
            {/* A dummy div gets inserted with extra margin when the player is on to make sure it does not hide
                any surrounding components, given its z-index, like the footer. */}
            {(showPlayer || forceShowMargin) && (
                <div>
                    {/* Local style not to load the full player stylesheet till shown. */}
                    <div style={{ marginTop: styleConstants.audioPlayerHeight }} />
                </div>
            )}
            {showPlayer && (
                <Suspense fallback={null}>
                    <LazyAudioPlayerContent />
                </Suspense>
            )}
        </>
    )
}
