import React, { createContext, useReducer } from 'react'

import type { AlertColor } from '@mui/material/Alert'

type ActionType = 'CLOSE' | 'OPEN' | 'PROGRESS'
type ReducerAction = Partial<SnackbarContextState> & { type: ActionType }

interface SnackbarContextState {
    autoHideDuration: number
    message: React.ReactNode
    open: boolean
    onClose: () => void
    severity: AlertColor
}

export interface SnackbarContextInterface extends SnackbarContextState {
    close: () => void
    dispatch: React.Dispatch<ReducerAction>
    error: (message?: React.ReactNode, onClose?: () => void) => void
    progress: (message: React.ReactNode) => void
    success: (message?: React.ReactNode, onClose?: () => void) => void
}

/**
 * Inject the Snackbar anywhere without importing the component, handling the corresponding state locally, etc. in
 * every single page implementing it.
 */
export const SnackbarContext = createContext<SnackbarContextInterface>(null)

const DEFAULT_AUTO_HIDE_DURATION = 5000

/**
 * Use a reducer given the complex logic of the Snackbar, involving multiple sub-values.
 */
const reducer = (state: SnackbarContextState, action: ReducerAction): SnackbarContextState => {
    switch (action.type) {
        case 'CLOSE':
            return {
                ...state,
                open: false
            }
        case 'OPEN':
            return {
                ...state,
                autoHideDuration: action.autoHideDuration,
                message: action.message,
                onClose: action.onClose,
                open: true,
                severity: action.severity
            }
        case 'PROGRESS':
            return {
                ...state,
                autoHideDuration: null, // Keep open until a success or error state has been reached.
                message: action.message,
                onClose: () => null,
                open: true,
                severity: 'success'
            }
    }
}

const initialState: SnackbarContextState = {
    autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
    message: '',
    onClose: () => null,
    open: false,
    severity: 'success'
}

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    // Convenient wrappers exposed externally to launch the Snackbar quickly in a given mode.
    const error: SnackbarContextInterface['error'] = (message, onClose) =>
        dispatch({
            autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
            message: message,
            onClose: onClose,
            severity: 'error',
            type: 'OPEN'
        })
    const progress: SnackbarContextInterface['progress'] = (message) => dispatch({ message: message, type: 'PROGRESS' })
    const success: SnackbarContextInterface['success'] = (message, onClose) =>
        dispatch({
            autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
            message: message,
            onClose: onClose,
            severity: 'success',
            type: 'OPEN'
        })
    const close: SnackbarContextInterface['close'] = () => dispatch({ type: 'CLOSE' })

    return (
        <SnackbarContext.Provider
            value={{
                ...state,
                // Context Methods.
                close: close,
                dispatch: dispatch,
                error: error,
                progress: progress,
                success: success
            }}
        >
            {children}
        </SnackbarContext.Provider>
    )
}
