/**
 * All our routes are gathered here for clarity and easy import (without having to import the `App` module itself).
 */
import React, { Suspense } from 'react'

import Container from '@mui/material/Container'
import type { QueryClient } from '@tanstack/react-query'
import type { Params } from 'react-router-dom'
import { defer, Outlet } from 'react-router-dom'

import Spinner, { SpinnerSuspense } from 'components/Template/Spinner'
import { episodeDetailQuery } from 'queries'
import { getVariables } from 'theme'
import importWithRetry from 'utils/import-with-retry'

const AccountSettings = React.lazy(() => importWithRetry(() => import('pages/AccountSettings/AccountSettings')))
const ArtifactCraftSuccess = React.lazy(() => importWithRetry(() => import('pages/PostInvite/ArtifactCraftSuccess')))
const ArtifactDetailOutlet = React.lazy(() =>
    importWithRetry(() => import('components/Navigation/ArtifactDetailOutlet'))
)
const ArtifactDetailRerouter = React.lazy(() =>
    importWithRetry(() => import('components/Navigation/ArtifactDetailOutlet'))
        // @ts-ignore
        .then((module) => ({ default: module.ArtifactDetailRerouter }))
)
const ArtifactsList = React.lazy(() => importWithRetry(() => import('pages/Dashboard/Library/ArtifactsList')))
const Authenticate = React.lazy(() => importWithRetry(() => import('pages/Authentication/Authenticate')))
const AuthenticatedOutlet = React.lazy(() => importWithRetry(() => import('components/Navigation/AuthenticatedOutlet')))
const Authentication = React.lazy(() => importWithRetry(() => import('pages/Listen/Authentication/Authentication')))
const CommissionedBy = React.lazy(() => importWithRetry(() => import('pages/PostInvite/CommissionedBy')))
const Conference = React.lazy(() => importWithRetry(() => import('pages/Conference/Conference')))
const ConferenceSuccess = React.lazy(() => importWithRetry(() => import('pages/Conference/ConferenceSuccess')))
const ConfirmInterview = React.lazy(() => importWithRetry(() => import('pages/InterviewDashboard/ConfirmInterview')))
const DashboardHome = React.lazy(() => importWithRetry(() => import('pages/Dashboard/Home/Home')))
const EmailVerification = React.lazy(() => importWithRetry(() => import('pages/EmailVerification/EmailVerification')))
const EpisodeDetail = React.lazy(() => importWithRetry(() => import('pages/EpisodeDetail/EpisodeDetail')))
const FollowUpDetail = React.lazy(() => importWithRetry(() => import('pages/FollowUp/FollowUpDetail')))
const GiftCardSetup = React.lazy(() =>
    importWithRetry(() => import('pages/GiftCards/ArtifactGiftCards/GiftCardSetup/GiftCardSetup'))
)
const HolidayCardTransitionOutlet = React.lazy(() =>
    importWithRetry(() => import('pages/HolidayCardDetail/HolidayCardTransitionOutlet'))
)
const ImageDetail = React.lazy(() => importWithRetry(() => import('pages/Gallery/ImageDetail')))
const ImageList = React.lazy(() => importWithRetry(() => import('pages/Gallery/ImageList')))
const InterviewDashboardLanding = React.lazy(() =>
    importWithRetry(() => import('pages/Authentication/InterviewDashboardLanding'))
)
const InterviewDashboardOutlet = React.lazy(() =>
    importWithRetry(() => import('pages/InterviewDashboard/InterviewDashboardOutlet'))
)
const InterviewDashboardRerouter = React.lazy(() =>
    importWithRetry(() => import('pages/InterviewDashboard/InterviewDashboardOutlet'))
        // @ts-ignore
        .then((module) => ({ default: module.InterviewDashboardRerouter }))
)
const InterviewDetails = React.lazy(() => importWithRetry(() => import('pages/InterviewDashboard/InterviewDetails')))
const InviteForm = React.lazy(() => importWithRetry(() => import('pages/InviteGuests/InviteForm')))
const Invites = React.lazy(() => importWithRetry(() => import('pages/InviteGuests/Invites')))
const InviteSchedule = React.lazy(() => importWithRetry(() => import('pages/PostInvite/InviteSchedule')))
const IsProducerInterviewed = React.lazy(() =>
    importWithRetry(() => import('pages/InviteGuests/IsProducerInterviewed'))
)
const ListenPageOutlet = React.lazy(() => importWithRetry(() => import('components/Navigation/ListenPageOutlet')))
const MomentDetail = React.lazy(() => importWithRetry(() => import('pages/MomentDetail/MomentDetail')))
const MomentList = React.lazy(() => importWithRetry(() => import('pages/MomentList/MomentList')))
const Navbar = React.lazy(() => importWithRetry(() => import('components/Layout/Navbar')))
const PaymentSuccess = React.lazy(() => importWithRetry(() => import('pages/Creation/PaymentSuccess')))
const Permissions = React.lazy(() => importWithRetry(() => import('pages/InterviewDashboard/Permissions')))
const PhotoBook = React.lazy(() => importWithRetry(() => import('pages/PhotoBook/PhotoBook')))
const PhotoBookSpreads = React.lazy(() => importWithRetry(() => import('pages/PhotoBook/PhotoBookSpreads')))
const PhotosAndGuidance = React.lazy(() => importWithRetry(() => import('pages/InterviewDashboard/PhotosAndGuidance')))
const PhotoUpload = React.lazy(() => importWithRetry(() => import('pages/FollowUp/PhotoUpload')))
const PostInterview = React.lazy(() => importWithRetry(() => import('pages/PostInterview/PostInterview')))
const PostInviteGuidance = React.lazy(() => importWithRetry(() => import('pages/PostInvite/Guidance')))
const Recording = React.lazy(() => importWithRetry(() => import('pages/FollowUp/Recording')))
const ResetPassword = React.lazy(() => importWithRetry(() => import('pages/Authentication/ResetPassword')))
const ResetPasswordConfirm = React.lazy(() =>
    importWithRetry(() => import('pages/Authentication/ResetPasswordConfirm'))
)
const SportsLibrary = React.lazy(() => importWithRetry(() => import('pages/SportsLibrary/SportsLibrary')))
const StoryRecorder = React.lazy(() => importWithRetry(() => import('pages/PhotoBook/StoryRecorder')))
const Subject = React.lazy(() => importWithRetry(() => import('pages/Creation/Subject/Subject')))
const SubscriptionConfirmation = React.lazy(() =>
    importWithRetry(() => import('pages/Payment/SubscriptionConfirmation'))
)
const Transcript = React.lazy(() => importWithRetry(() => import('pages/Listen/TranscriptDetail/Transcript')))
const UploadMedia = React.lazy(() => importWithRetry(() => import('pages/PostInvite/UploadMedia')))

const episodeDetailLoader =
    (queryClient: QueryClient) =>
    async ({ params }: { params: Params }) => {
        const query = episodeDetailQuery(params.episodeSlug)
        const episodeDetailPromise = queryClient.getQueryData(query.queryKey) ?? queryClient.fetchQuery(query)
        return defer({ episodeDetail: episodeDetailPromise })
    }

export const routes = (queryClient: QueryClient) => [
    ////////////////////////////////////
    // Dashboard
    ////////////////////////////////////
    {
        element: (
            <SpinnerSuspense>
                <AuthenticatedOutlet />
            </SpinnerSuspense>
        ),
        children: [
            {
                path: 'dashboard',
                children: [
                    {
                        path: ':artifactHashid/interviews/*',
                        element: <InterviewDashboardOutlet />,
                        children: [
                            {
                                path: 'manage',
                                element: (
                                    <SpinnerSuspense>
                                        <PhotosAndGuidance />
                                    </SpinnerSuspense>
                                )
                            },
                            { path: 'listeners', element: <Permissions /> },
                            { path: ':interviewHashid', element: <InterviewDetails /> },
                            { path: '', element: <InterviewDashboardRerouter /> }
                        ]
                    },
                    {
                        path: ':artifactHashid/*',
                        element: (
                            <SpinnerSuspense>
                                <ArtifactDetailOutlet />
                            </SpinnerSuspense>
                        ),
                        children: [
                            {
                                path: 'payment-success',
                                element: (
                                    <SpinnerSuspense>
                                        <PaymentSuccess />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'commissioned-by',
                                element: (
                                    <SpinnerSuspense>
                                        <CommissionedBy />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'subjects',
                                element: (
                                    <SpinnerSuspense>
                                        <Subject />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'producer-interviewed',
                                element: (
                                    <SpinnerSuspense>
                                        <IsProducerInterviewed />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'new-guest',
                                element: (
                                    <SpinnerSuspense>
                                        <InviteForm />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'edit-guest/:participationId',
                                element: (
                                    <SpinnerSuspense>
                                        <InviteForm />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'guests',
                                element: (
                                    <SpinnerSuspense>
                                        <Invites />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'guidance',
                                element: (
                                    <SpinnerSuspense>
                                        <PostInviteGuidance />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'invite-schedule',
                                element: (
                                    <SpinnerSuspense>
                                        <InviteSchedule />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'upload-media',
                                element: (
                                    <SpinnerSuspense>
                                        <UploadMedia />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: 'craft-success',
                                element: (
                                    <SpinnerSuspense>
                                        <ArtifactCraftSuccess />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: '*',
                                element: (
                                    <SpinnerSuspense>
                                        <ArtifactDetailRerouter />
                                    </SpinnerSuspense>
                                )
                            }
                        ]
                    },
                    { path: 'settings', element: <AccountSettings /> },
                    {
                        path: 'email-verification',
                        element: (
                            <SpinnerSuspense>
                                <EmailVerification />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'library',
                        element: (
                            <SpinnerSuspense>
                                <ArtifactsList />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: '',
                        element: (
                            <SpinnerSuspense>
                                <DashboardHome />
                            </SpinnerSuspense>
                        )
                    }
                ]
            }
        ]
    },

    ////////////////////////////////////
    // TBYB
    ////////////////////////////////////
    {
        path: 'confirm/:interviewHashid',
        element: (
            <SpinnerSuspense>
                <ConfirmInterview />
            </SpinnerSuspense>
        )
    },

    ////////////////////////////////////
    // Voximplant & Login
    ////////////////////////////////////
    {
        element: (
            <>
                {/* TODO: Make it a proper Layout? We might want to introduce a variant with a bottom border
                    for that. Or re-use the `whiteAppBar` one? */}
                <Suspense fallback={<div />}>
                    <Navbar />
                </Suspense>
                <Outlet />
            </>
        ),
        children: [
            {
                element: (
                    <Container maxWidth='lg'>
                        <Outlet />
                    </Container>
                ),
                children: [
                    {
                        path: 'get-started/payment-success',
                        element: (
                            <SpinnerSuspense>
                                <PaymentSuccess />
                            </SpinnerSuspense>
                        )
                    }
                ]
            },

            {
                element: (
                    <div style={{ paddingTop: '32px' }}>
                        <Outlet />
                    </div>
                ),
                children: [
                    {
                        path: 'gift-card-setup/:giftCardHashid',
                        element: (
                            <SpinnerSuspense>
                                <GiftCardSetup />
                            </SpinnerSuspense>
                        )
                    },

                    {
                        path: 'post-interview/:interviewPin',
                        element: (
                            <SpinnerSuspense>
                                <PostInterview />
                            </SpinnerSuspense>
                        )
                    }
                ]
            },

            {
                path: 'conference/thank-you',
                element: (
                    <SpinnerSuspense>
                        <ConferenceSuccess />
                    </SpinnerSuspense>
                )
            },
            {
                path: 'conference',
                element: (
                    <SpinnerSuspense>
                        <Conference />
                    </SpinnerSuspense>
                )
            },

            {
                element: (
                    <Container maxWidth='lg' style={{ paddingTop: '32px' }}>
                        <Outlet />
                    </Container>
                ),
                children: [
                    {
                        path: 'join/:interviewHashid',
                        element: (
                            <SpinnerSuspense>
                                <InterviewDashboardLanding />
                            </SpinnerSuspense>
                        )
                    },

                    {
                        path: 'login',
                        element: (
                            <SpinnerSuspense>
                                <Authenticate />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'password-reset/email',
                        element: (
                            <SpinnerSuspense>
                                <ResetPassword />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'password-reset/confirm/:uid/:token',
                        element: (
                            <SpinnerSuspense>
                                <ResetPasswordConfirm />
                            </SpinnerSuspense>
                        )
                    }
                ]
            },

            {
                path: 'subscription-confirmation',
                element: (
                    <SpinnerSuspense>
                        <SubscriptionConfirmation />
                    </SpinnerSuspense>
                )
            }
        ]
    },

    {
        element: <Outlet />,
        children: [
            {
                path: 'creations/:episodeSlug',
                children: [
                    {
                        path: 'photos',
                        children: [
                            // Keep the gallery image list login route before the image detail route so that `login` is not confused
                            // as a hashid.
                            {
                                path: 'login',
                                element: (
                                    <SpinnerSuspense>
                                        <Authentication />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: ':imageHashid/login',
                                element: (
                                    <SpinnerSuspense>
                                        <Authentication />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: ':imageHashid',
                                element: (
                                    <SpinnerSuspense>
                                        <ImageDetail />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: '',
                                element: (
                                    <SpinnerSuspense>
                                        <ImageList />
                                    </SpinnerSuspense>
                                )
                            }
                        ]
                    },
                    {
                        path: 'login',
                        element: (
                            <SpinnerSuspense>
                                <Authentication />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'moments',
                        element: (
                            <SpinnerSuspense>
                                <MomentList />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'transcript/login',
                        element: (
                            <SpinnerSuspense>
                                <Authentication />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: '',
                        element: (
                            <Suspense fallback={<Spinner containerStyle={{ backgroundColor: getVariables().dark }} />}>
                                <ListenPageOutlet />
                            </Suspense>
                        ),
                        children: [
                            {
                                path: 'transcript',
                                element: (
                                    <SpinnerSuspense>
                                        <Transcript />
                                    </SpinnerSuspense>
                                )
                            },
                            {
                                path: '',
                                element: (
                                    <SpinnerSuspense>
                                        <EpisodeDetail />
                                    </SpinnerSuspense>
                                ),
                                loader: episodeDetailLoader(queryClient)
                            }
                        ]
                    }
                ]
            },
            {
                path: 'follow-ups/:followUpHashid',
                children: [
                    {
                        path: '',
                        element: (
                            <SpinnerSuspense>
                                <FollowUpDetail />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'recording',
                        element: (
                            <SpinnerSuspense>
                                <Recording />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: 'photos',
                        element: (
                            <SpinnerSuspense>
                                <PhotoUpload />
                            </SpinnerSuspense>
                        )
                    }
                ]
            },
            {
                path: 'moments/:momentHashid',
                element: (
                    <SpinnerSuspense>
                        <MomentDetail />
                    </SpinnerSuspense>
                )
            },
            {
                path: 'playlists',
                children: [
                    {
                        path: ':playlistHashid/new',
                        element: (
                            <SpinnerSuspense>
                                <StoryRecorder />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: ':playlistHashid',
                        element: (
                            <SpinnerSuspense>
                                <PhotoBookSpreads />
                            </SpinnerSuspense>
                        )
                    },
                    {
                        path: '',
                        element: (
                            <SpinnerSuspense>
                                <PhotoBook />
                            </SpinnerSuspense>
                        )
                    }
                ]
            },
            {
                path: 'holiday-cards/:holidayCardHashid/recording',
                element: (
                    <SpinnerSuspense>
                        <StoryRecorder />
                    </SpinnerSuspense>
                )
            },
            // The HolidayCardTransition component determines what to render based on the current path in order to
            // provide a smooth transition.
            ...['holiday-cards/:holidayCardHashid/unboxing', 'holiday-cards/:holidayCardHashid'].map((path) => ({
                path,
                element: (
                    <SpinnerSuspense>
                        <HolidayCardTransitionOutlet />
                    </SpinnerSuspense>
                )
            })),
            {
                path: 'libraries/sports/:playlistHashid',
                element: (
                    <SpinnerSuspense>
                        <SportsLibrary />
                    </SpinnerSuspense>
                )
            }
        ]
    }
]
