import React, { createContext, useContext, useRef } from 'react'

import type { Swiper as SwiperType } from 'swiper/types'

import { SitbackContext } from 'contexts/SitbackProvider'

export interface MomentSwiperContextInterface {
    momentSwiperRef: React.MutableRefObject<SwiperType>
    scrollToMoment: (index: number) => void
}

export const MomentSwiperContext = createContext<MomentSwiperContextInterface>(null)

export default function MomentSwiperProvider({ children }: { children: React.ReactNode }) {
    const momentSwiperRef = useRef<SwiperType>()
    const { isSitbackActive } = useContext(SitbackContext)

    const scrollToMoment = (index: number) => {
        if (!isSitbackActive) {
            // Only change slides if the ref is defined and is still mounted.
            if (momentSwiperRef.current && !momentSwiperRef.current.destroyed) momentSwiperRef.current.slideTo(index)
            const momentElement = momentSwiperRef.current?.el
            // Scroll the moment into the center of the screen.
            if (momentElement) momentElement.scrollIntoView({ block: 'center' })
        }
    }

    const context: MomentSwiperContextInterface = {
        momentSwiperRef,
        scrollToMoment
    }

    return <MomentSwiperContext.Provider value={context}>{children}</MomentSwiperContext.Provider>
}
