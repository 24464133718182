import React, { createContext, useState } from 'react'

export const INITIAL_AUDIO_PROPERTIES: AudioProperties = {
    content_type: 'episode',
    source: '',
    title: globals.episodeTitle
}

export interface AudioPlayerContextInterface {
    audioProperties: AudioProperties
    isMuted: boolean
    setAudioProperties: React.Dispatch<React.SetStateAction<AudioProperties>>
    setIsMuted: React.Dispatch<React.SetStateAction<boolean>>
    setShowPlayer: React.Dispatch<React.SetStateAction<boolean>>
    showPlayer: boolean
}

export const AudioPlayerContext = createContext<AudioPlayerContextInterface | null>(null)

interface AudioPlayerProviderProps {
    children: React.ReactNode
}

/**
 * Handle custom state variables, in addition to our hooks wrapping 'react-use-audio-player'.
 */
export default function AudioPlayerProvider({ children }: AudioPlayerProviderProps) {
    // The sticky bottom player appears the first time the user presses "Play".
    const [showPlayer, setShowPlayer] = useState(false)

    // Propagate episode title updates by the producer to the audio player, but also handle title updates across
    // the post-purchase experience & interview dashboard audio greetings.
    const [audioProperties, setAudioProperties] = useState({ ...INITIAL_AUDIO_PROPERTIES })
    const [isMuted, setIsMuted] = useState(false)

    const context: AudioPlayerContextInterface = {
        audioProperties,
        isMuted,
        setAudioProperties,
        setIsMuted,
        setShowPlayer,
        showPlayer
    }

    return (
        <AudioPlayerContext.Provider value={context}>
            {' '}
            {/* The current context provider. */}
            {children}
        </AudioPlayerContext.Provider>
    )
}
