import type { SimplePaletteColorOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

// @see https://mui.com/x/react-date-pickers/getting-started/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation'
import colors from 'theme/colors.json'
import styleConstants from 'theme/constants.json'
import { hexToRGB } from 'utils/misc'

const BUTTON_FONT_SIZE_LARGE = '1.5rem'
const BUTTON_FONT_SIZE_MEDIUM = '1.25rem'
const BUTTON_FONT_SIZE_SMALL = '1rem'
export const MOBILE_BREAKPOINT = 'md'

export function getVariables() {
    /**
     * If a non-color value is included in colors.json, be sure to exclude it from the Storybook palette.
     * The colors are extracted in this file: luc/.storybook/utils.tsx.
     * The number in the color name corresponds to the opacity of the color.
     * For example: `yellow75` is the yellow color if it were 75% opaque on a white background.
     * The colors are defined in a json file to be able to easily import them in Sass.
     */

    // TODO: Update the lightBlue color to match our design theme and audit components that used it.
    return colors
}

export function getPalette(variant: 'light' | 'dark' = 'light') {
    // Used when passing `color='textPrimary/Secondary' to <Typography/>` and also for the button text color.
    // For the dark variant, let Mui pick the default colors.
    const textColorOverride =
        variant === 'light'
            ? {
                  text: {
                      primary: getVariables().darkGrey,
                      secondary: getVariables().white
                  }
              }
            : {}
    return {
        /**
         * MUI overrides.
         */
        mode: variant,
        primary: {
            contrastText: getVariables().white,
            dark: getVariables().primaryRedDark,
            main: getVariables().primaryRedMain
        },
        secondary: {
            contrastText: getVariables().white,
            dark: getVariables().secondaryBlueDark,
            main: getVariables().secondaryBlueMain
        },
        ...textColorOverride,
        /**
         * Custom additions.
         * @see https://chir.ag/projects/name-that-color/ to map a hexacode to a color name.
         */
        alabaster: {
            main: '#FAFAFA'
        },
        blue: {
            main: getVariables().blue // Used for the volume icon in the timestamp chip, the emoji icon to add reactions...
        },
        elephant: {
            main: '#164457'
        },
        teal: {
            main: '#2D6275' // Used for the background color of the emoji bar.
        },
        transparentBlack: {
            main: '#00000080'
        },
        transparentLightGray: {
            main: getVariables().layoutGrey50
        },
        yellow: {
            main: getVariables().yellow
        }
    }
}

/**
 * Get the theme injected at the app level, potentially overwritten by nested themes wrapping specific components.
 */
export function getTheme(variant: 'light' | 'dark' = 'light') {
    const baseTheme = createTheme()
    const mobileBreakpoint = baseTheme.breakpoints.down(MOBILE_BREAKPOINT)
    const { augmentColor } = baseTheme.palette

    const createColor = (colorOptions: SimplePaletteColorOptions) => augmentColor({ color: colorOptions })

    const body1Style = {
        fontSize: '1.125rem',
        fontFamily: 'TTInterfaces-Regular',
        [mobileBreakpoint]: {
            fontSize: '0.875rem'
        }
    }

    const subtitle2Style = {
        fontSize: '1rem',
        fontFamily: 'TTInterfaces-Medium',
        [mobileBreakpoint]: {
            fontSize: '0.875rem'
        }
    }

    const body2Style = {
        fontSize: '1rem',
        fontFamily: 'TTInterfaces-Regular',
        [mobileBreakpoint]: {
            fontSize: '0.75rem'
        }
    }

    const filledAlertStyle = {
        border: `solid 1px ${getVariables().roundedButtonGrey}`
    }

    return createTheme({
        breakpoints: {
            values: {
                ...baseTheme.breakpoints.values,
                xl: styleConstants.xlBreakpoint
            }
        },
        components: {
            MuiAlert: {
                styleOverrides: {
                    filled: {
                        color: '#fff'
                    },
                    filledError: {
                        ...filledAlertStyle,
                        backgroundColor: getPalette().primary.main
                    },
                    filledInfo: {
                        ...filledAlertStyle,
                        backgroundColor: getVariables().dark
                    },
                    filledSuccess: {
                        ...filledAlertStyle,
                        backgroundColor: getVariables().blueGreen
                    },
                    filledWarning: {
                        ...filledAlertStyle,
                        backgroundColor: getPalette().primary.main
                    },
                    root: {
                        ...body2Style
                    },
                    standardWarning: {
                        backgroundColor: hexToRGB(getPalette().yellow.main, 0.2)
                    },
                    standardInfo: {
                        backgroundColor: hexToRGB(getPalette().secondary.main, 0.2)
                    }
                }
            },
            MuiAlertTitle: {
                styleOverrides: {
                    root: {
                        ...subtitle2Style
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        borderBottom: `1px solid ${getVariables().layoutGrey}`,
                        boxShadow: 'none'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        boxShadow: 'none',
                        '&:active, &:focus, &:hover': {
                            boxShadow: 'none'
                        },
                        '&:disabled': {
                            backgroundColor: getVariables().dark25,
                            color: getVariables().white
                        },
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: getVariables().white
                        }
                    },
                    containedTertiary: {
                        fontFamily: 'TTInterfaces-Medium'
                    },
                    iconSizeLarge: {
                        '& > svg:nth-of-type(1)': {
                            fontSize: BUTTON_FONT_SIZE_LARGE,
                            [mobileBreakpoint]: {
                                fontSize: BUTTON_FONT_SIZE_MEDIUM
                            }
                        }
                    },
                    iconSizeMedium: {
                        '& > svg:nth-of-type(1)': {
                            fontSize: BUTTON_FONT_SIZE_MEDIUM,
                            [mobileBreakpoint]: {
                                fontSize: BUTTON_FONT_SIZE_SMALL
                            }
                        }
                    },
                    iconSizeSmall: {
                        '& > svg:nth-of-type(1)': {
                            fontSize: BUTTON_FONT_SIZE_SMALL
                        }
                    },
                    outlined: {
                        ...body1Style
                    },
                    outlinedTertiary:
                        variant === 'light'
                            ? {
                                  borderColor: getVariables().dark25,
                                  color: getVariables().dark75,
                                  '&:hover': {
                                      backgroundColor: hexToRGB(getVariables().dark, 0.04),
                                      borderColor: getVariables().dark50,
                                      color: getVariables().dark
                                  }
                              }
                            : {
                                  borderColor: hexToRGB(getVariables().white, 0.3),
                                  color: getVariables().white,
                                  '&:hover': {
                                      backgroundColor: hexToRGB(getVariables().white, 0.1),
                                      borderColor: getVariables().dark25
                                  }
                              },
                    sizeLarge: {
                        borderRadius: '16px',
                        fontSize: BUTTON_FONT_SIZE_LARGE,
                        padding: '20px 32px',
                        [mobileBreakpoint]: {
                            fontSize: '1.125rem'
                        }
                    },
                    sizeMedium: {
                        borderRadius: '12px',
                        fontSize: BUTTON_FONT_SIZE_MEDIUM,
                        padding: '16px 24px',
                        [mobileBreakpoint]: {
                            fontSize: '0.875rem'
                        }
                    },
                    sizeSmall: {
                        borderRadius: '8px',
                        fontSize: BUTTON_FONT_SIZE_SMALL,
                        padding: '12px 20px',
                        [mobileBreakpoint]: {
                            fontSize: '0.75rem'
                        }
                    },
                    // Used for instance as the main settings CTA at the Artifact level in the Studio Home.
                    textPrimary: {
                        '&:hover': {
                            backgroundColor: hexToRGB(getVariables().secondaryBlueMain, 0.05)
                        },
                        color: getVariables().secondaryBlueMain
                    },
                    // Used for instance for links in dark green navigation bars.
                    textSecondary: {
                        color: getVariables().white
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    // Propagate the color to the path downstream, filled with the same color, to prevent the border from
                    // being white and therefore hidden, on a white background.
                    colorPrimary: {
                        color: getPalette().primary.main
                    },
                    colorSecondary: {
                        color: getPalette().secondary.main
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        color: getPalette().text.primary
                    }
                }
            },
            MuiDialogContentText: {
                styleOverrides: {
                    root: {
                        color: getPalette().text.primary
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        backgroundColor: getVariables().dark,
                        color: getPalette().text.secondary
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: getVariables().dark,
                        color: getPalette().text.secondary
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: hexToRGB(getPalette().text.primary, 0.5)
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    // Additional variant to show white X close icons in menus.
                    colorAction: {
                        color: getVariables().white
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            color: getPalette().text.primary,
                            // The opacity is set separately on Mui's end, hence the need for setting it as well (instead
                            // of using `hexToRGB`.
                            opacity: 0.5
                        },
                        // Do not set the font size smaller than 1rem on mobile, as it would trigger a zoom effect on
                        // iOS upon text entry.
                        [mobileBreakpoint]: {
                            fontSize: '1rem'
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        ...body1Style,
                        color: hexToRGB(getPalette().text.primary, 0.5)
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    button: {
                        fontSize: 'inherit',
                        verticalAlign: 'none'
                    }
                }
            },
            // Use white icons in lists of links (e.g., Listen Page navigation).
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: getVariables().white
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        border: 'none', // Prevent the superposition of two borders.
                        backgroundColor: getVariables().white,
                        color: '#000'
                    },
                    notchedOutline: {
                        border: `1px solid ${getVariables().layoutGrey}`,
                        borderColor: getVariables().layoutGrey, // By default (0, 0, 0, 0.23) despite our border attribute.
                        borderRadius: 7
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    // Always add a light gray border to flat Papers (e.g., in the Payment page), but not inside Drawers.
                    outlined: {
                        border: `solid 1px ${getVariables().layoutGrey}`,
                        borderRadius: '12px'
                    }
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    // TODO: Investigate why the `selected` subclass doesn't work on its own.
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: getPalette().secondary.main,
                            '&:hover': {
                                backgroundColor: hexToRGB(getPalette().secondary.main, 0.8)
                            },
                            '&:focus': {
                                backgroundColor: '#409EAF'
                            }
                        }
                    },
                    today: {
                        color: getPalette().secondary.main
                    }
                }
            },
            MuiPickerStaticWrapper: {
                styleOverrides: {
                    root: {
                        // Add a border, but only to the inline version aka static, not the dialog one.
                        border: `solid 1px ${getVariables().layoutGrey}`,
                        borderRadius: '12px',
                        overflow: 'hidden',
                        paddingBottom: '12px', // The inner part of the widget feels crowded without it.,
                        '& .MuiDatePickerToolbar-root': {
                            color: getVariables().white
                        }
                    }
                }
            },
            MuiPickersToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: getPalette().secondary.main
                    }
                }
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        margin: 'auto' // Center the calendar when the container width is slightly bigger.
                    }
                }
            },
            MuiRadio: {
                styleOverrides: {
                    // Propagate the color to the path downstream, filled with the same color, to prevent the border from
                    // being white and therefore hidden, on a white background.
                    colorPrimary: {
                        color: getPalette().primary.main
                    },
                    colorSecondary: {
                        color: getPalette().secondary.main
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    textColorPrimary: {
                        color: getVariables().grey54
                    }
                }
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        '& .MuiToggleButton-root': {
                            padding: '2px 0 2px 0'
                        },
                        '& .MuiToggleButton-root.Mui-selected': {
                            backgroundColor: hexToRGB(getPalette().secondary.main, 0.2)
                        }
                    }
                }
            }
        },
        palette: {
            ...getPalette(variant),
            tertiary: createColor({ contrastText: '#162F37', main: '#ffffff' })
        },
        typography: {
            fontFamily: ['TTInterfaces-Regular'].join(','),
            h1: {
                fontSize: '2.5rem',
                fontFamily: 'GT-Super-Bold',
                [mobileBreakpoint]: {
                    fontSize: '1.75rem'
                }
            },
            h2: {
                fontSize: '2rem',
                fontFamily: 'GT-Super-Bold',
                [mobileBreakpoint]: {
                    fontSize: '1.5rem'
                }
            },
            h3: {
                fontSize: '1.75rem',
                fontFamily: 'GT-Super-Bold',
                [mobileBreakpoint]: {
                    fontSize: '1.25rem'
                }
            },
            h4: {
                fontSize: '1.5rem',
                fontFamily: 'GT-Super-Bold',
                [mobileBreakpoint]: {
                    fontSize: '1.125rem'
                }
            },
            h5: {
                fontSize: '1.25rem',
                fontFamily: 'GT-Super-Medium',
                [mobileBreakpoint]: {
                    fontSize: '1rem'
                }
            },
            h6: {
                fontSize: '1rem',
                fontFamily: 'GT-Super-Medium',
                [mobileBreakpoint]: {
                    fontSize: '0.875rem'
                }
            },
            subtitle1: {
                fontSize: '1.25rem',
                fontFamily: 'TTInterfaces-Medium',
                lineHeight: '2rem',
                [mobileBreakpoint]: {
                    fontSize: '1rem'
                }
            },
            subtitle2: {
                ...subtitle2Style
            },
            body1: {
                ...body1Style
            },
            body2: {
                ...body2Style
            },
            caption: {
                fontSize: '0.75rem',
                fontFamily: 'TTInterfaces-Regular'
            },
            button: {
                fontFamily: 'TTInterfaces-Medium',
                fontSize: '1rem',
                fontWeight: 600,
                letterSpacing: 'normal',
                lineHeight: 1.1,
                textTransform: 'none',
                [mobileBreakpoint]: {
                    fontSize: '0.875rem'
                }
            }
        }
    })
}

export const getGalleryTheme = () =>
    createTheme(getTheme(), {
        palette: {
            background: { default: '#000' },
            text: { primary: '#fff' }
        },
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: getVariables().mineShaft,
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: getVariables().mineShaft
                        }
                    }
                }
            }
        }
    })
