import React, { Suspense, useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

interface SpinnerProps {
    className?: string
    color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    containerStyle?: React.CSSProperties
    fullScreen?: boolean
    size?: string // In pixels - which should be specified e.g., '15px'.
    style?: React.CSSProperties
}

/*
 * Render a Circular Progress component to be displayed either:
 *   - horizontally centered within a container, while a whole page is loading, with some top margin.
 *   - on its own to be integrated into a button, to inform the user about an ongoing process (handling an action...)
 */
export default function Spinner({
    className,
    color = 'primary',
    containerStyle = {},
    fullScreen = true,
    size,
    style = {}
}: SpinnerProps) {
    const [progress, setProgress] = useState(0)

    // Control the evolution of the progress (though it's indeterminate), similarly to the Material UI demo component.
    useEffect(() => {
        function tick() {
            // Reset when reaching 100%.
            setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1))
        }

        const timer = setInterval(tick, 20)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const circularProgress = (
        <CircularProgress
            className={className}
            color={color}
            value={progress}
            size={size}
            style={{ marginTop: fullScreen ? '10%' : 0, ...style }}
        />
    )

    if (fullScreen === true) {
        return (
            <div style={{ height: '100%', textAlign: 'center', width: '100%', ...containerStyle }}>
                {circularProgress}
            </div>
        )
    } else {
        return circularProgress
    }
}

export const SpinnerSuspense = ({ children }: { children: React.ReactNode }) => (
    <Suspense fallback={<Spinner />}>{children}</Suspense>
)
